// React Components
import * as React from 'react';
import { useState, useEffect } from "react";
import axios from "axios";

// Material UI Components

// Audit Vault Components
import ProgressBar from '../common/ProgressBar';
import ServiceIsDown from "../common/ServiceIsDown";

// Audit Vault Utilities
import { encryptKey } from '../../utilities/common-encrypt-util';
import { getCompanyByAzureTenantId } from '../../utilities/common-company';

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;



export default function DisplayFullAuditLogDetail(props) {

  const { tenantId, reportType, auditLogId, user, userAzureTenantId } = props;
  const [loading, setLoading] = useState(false);
  const [serviceIsDownError, setServiceIsDownError] = useState(false);
  const [auditLogDetails, setAuditLogDetails] = useState([]);  

  const [isOperationFileOrFolderMoved, setIsOperationFileOrFolderMoved] = useState(false);  
  const [movedSourceItemId, setMovedSourceItemId] = useState();

  async function fetchAuditLogData() 
  {
    try {
        setLoading(true);

        var usrEmail = "N/A";
        if (user.userEmail)
            usrEmail = user.userEmail;

        var azTenantId = userAzureTenantId;
        if (!userAzureTenantId)
          azTenantId = "";
        
        var payload = `{
            companyId: "${user.companyId}",
            tenantId: ${tenantId},
            auditLogId: "${auditLogId}",
            reportType: "${reportType}",
            userAzureTenantId: "${azTenantId}",                     
            userMicrosoftGraphId: "${await encryptKey(user.microsoftGraphId)}",
            userEmail: "${await encryptKey(usrEmail)}"               
        }`; 
        
        var response = await axios.get(`${WEB_API_URL}Reports/GetAuditLogDetailById`, {
            params: {
                key: `${payload}`
            }
        });
        
        if (response)
        {
          // Set auditLogDetail          
          setAuditLogDetails(response.data);
          
          // Sept 19, 2024: Take note if FileMoved operation detected, and save the sourceItemId in that case.
          // Only extract SourceItemId if operation is FileMoved.          
          if (response.data.operation === 'FileMoved' || response.data.operation === 'FolderMoved') {
              setIsOperationFileOrFolderMoved(true);
              const eventData = response.data.eventData || '';
              const sourceItemIdMatch = eventData.match(/<SourceItemId>(.*?)<\/SourceItemId>/);
              setMovedSourceItemId(sourceItemIdMatch ? sourceItemIdMatch[1] : null);
          }
          else {
            setIsOperationFileOrFolderMoved(false);
          }
      
          // console.log(response)
        }

    }
    catch (e) {
      console.log("ERROR: DisplayFullAuditLogDetails.fetchAuditLogData")
      console.log(e);
      setServiceIsDownError(true);
    }
    finally{
      setLoading(false);
    }
  }

  useEffect(() => {
      fetchAuditLogData();
  }, []);


  // If we are still loading data, display that in the UI...
  if (loading) {
    return (<ProgressBar message="Loading ..." loading={loading} />);
  }

  // Component UI
  if (serviceIsDownError) {
    return (<><ServiceIsDown></ServiceIsDown></>);
  }

  return (
    <>

      <span style={{ fontWeight: 'bold' }}>Audit Log Details</span><br />
      <span style={{ fontSize: '12px' }}>
        {
          /* Sept 19, 2024: Added link to display only if operation=FileMoved and the sourceItemId exists. */
          // Only show the link if operation is FileMoved and SourceItemId exists
          isOperationFileOrFolderMoved == true && movedSourceItemId && (
            <div>
              <b>The audit details below indicate this item had been moved from another Site.</b>
              <br />The pre-move audit history can be found here: <a href={`/Reports/SharePointItemAuditHistoryReport/${movedSourceItemId}`} target="_blank">Link to pre-move Audit History</a>
            </div>
          )
        }
      </span>
      <span style={{ fontSize: '10px' }}>                                                           
        {/*
        CompanyId: { user.companyId }<br />TenantId: { tenantId }<br />ReportType: { reportType }<br />Id: { auditLogId }<br />User: { user.userEmail }<br />VALUES:  
        */
        }
        {
          Object.entries(auditLogDetails).map(([key, value], index) => (
            value !== null && (<div key={index}>{`${key}: ${value}`}</div>)
          ))
        }
      </span>

    </>
  );
    
}
