// React Components
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import dayjs from 'dayjs';

// Material UI Components
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Audit Vault Components
import AccessDenied from "../../components/common/AccessDenied";
import DisplayBreadCrumb from "../../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../../components/common/DisplaySectionTitleAndDescription";
import ServiceIsDown from "../../components/common/ServiceIsDown";
import ProgressBar from '../../components/common/ProgressBar';

// Audit Vault Utilities
import { formatDate } from '../../utilities/common-date-utils';
import getAuditSource from '../../utilities/common-auditsource-util';
import { getTenantStatusColour } from "../../utilities/common-tenant-utils";
import { encryptKey } from '../../utilities/common-encrypt-util';

// constants
const theSectionTitle = "Audit Vault System Jobs List";
const theSectionDesc = "";
const isAdminPage = true;

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Admin', link: '/Admin/AdminHome' },
    { name: 'System Jobs List', link: '/Admin/SystemJobs' }
];

function SystemJobsList(props) {

    const { user } = props;
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDown, setServiceIsDown] = useState(false);

    const [jobs, setJobs] = useState([]);
    const [tenants, setTenants] = useState([]);

    // Need page variables for each audit type
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [sortConfig, setSortConfig] = useState(null);  // Used for sorting column header.

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Component Functions
    async function fetchData() {
        if (loading && user) {
            try {
                var result = null;
                var result2 = null;
                var data = null;
                if (user.isSystemAdmin) {
                    data = {
                        sysAdminId: user.microsoftGraphId,
                    };
                    var keyString = await encryptKey(JSON.stringify(data));
                    if (keyString) {
                        // Get all job queues.                                          
                        result = await axios.get(`${WEB_API_URL}AdminUtilities/GetAllJobQueues`, {
                            params: {
                                key: keyString,
                            }
                        });
                        if (result) {
                            console.log(result.data);
                            setJobs(result.data);
                        }
                        // Get all Tenants.
                        result2 = await axios.get(`${WEB_API_URL}AdminUtilities/GetTenants`, {
                            params: {
                                key: keyString,
                            }
                        });
                        if (result2) {
                            setTenants(result2.data);
                        }
                    }
                }
                else {
                    setAccessDenied(true);
                }
            }
            catch (e) {
                setServiceIsDown(true);
                setAccessDenied(true);
                console.log("ERROR: SystemJobsList:fetchData");
                console.log(e);
            }
            finally {
                setLoading(false);
            }
        }
    }

    // Column header sort function.
    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    // Function for Sorting column headers.  
    function stableSort(array, comparator) {

        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    // Function for Sorting column headers.
    function getComparator(sortConfig) {
        return (a, b) => {
            if (!sortConfig) {
                return 0;
            }
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        };
    }


    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);
        const [jobHistory, setJobHistory] = React.useState([]);
        const [jobDetails, setJobDetails] = React.useState("");
        const [loadingRow, setLoadingRow] = useState(false);
        const [xNumOfDays, setXNumOfDays] = useState(0);

        const xNumOfDayVals = Array.from({ length: 7 }, (_, index) => index + 1);
        const xNumOfDayValue = useRef(0);

        // Need page variables for each audit type
        const [page2, setPage2] = React.useState(0);
        const [rowsPerPage2, setRowsPerPage2] = React.useState(25);

        const handleChangePage2 = (event, newPage) => {
            setPage2(newPage);
        };

        const handleChangeRowsPerPage2 = (event) => {
            setRowsPerPage2(+event.target.value);
            setPage2(0);
        };

        const getJobHeath = (job) => {
            var status = 1;
            console.log(job)
            const oldDate = '01/01/1901 12:00:00 PM';
            if (Date.parse(job.lastSuccessfulRunEndTime) > Date.parse(oldDate)) {
                // Job may have not run yet

                // If the job hasn't been success for more than 36 hours, mark as warning
                const warningDate = new Date(new Date().getTime() - (36 * 60 * 60 * 1000));
                if (Date.parse(job.lastSuccessfulRunEndTime) < Date.parse(warningDate)) {
                    status = 2;
                    console.log(status)
                    console.log(warningDate)
                }

                // if the job hasn't been successful for more than 3 days mark as Critical
                const criticalDate = new Date(new Date().getTime() - (62 * 60 * 60 * 1000));
                if (Date.parse(job.lastSuccessfulRunEndTime) < Date.parse(criticalDate)) {
                    status = 3;
                    console.log(status)
                    console.log(warningDate)
                }

                if (!job.isLastRunSuccessful) {
                    console.log(status)
                    status = 3;
                }

            }
            else {
                status = 0
            }

            return (
                <Typography color={getTenantStatusColour(status)}>
                    <CircleRoundedIcon color={getTenantStatusColour(status)} sx={{ paddingRight: 1, alignItems: "center" }} />
                </Typography>
            )
        }

        const handleOpen = async (val, jobId) => {
            console.log(val);
            setOpen(val);

            if (val) {
                setLoadingRow(true);
                try {

                    var rsaKey = `{TenantId: "${await encryptKey(row.tenantId.toString())}", UserId: "${await encryptKey(user.microsoftGraphId)}", JobId: "${row.id.toString()}", CompanyId: "${row.companyId.toString()}" }`;
                    if (rsaKey) {

                        var result = await axios.get(`${WEB_API_URL}AdminUtilities/GetJobQueueDetailsById`, {
                            params: {
                                key: `${rsaKey}`
                            }
                        });
                    }

                    if (result) {
                        setJobHistory(result.data.history);
                        setJobDetails(result.data);

                        switch (row.auditTypeAssigned) {
                            case 0: // SharePoint
                                var spXNumOfDays = tenants.find(tenant => tenant.id === row.tenantId).sharePointGetXLastNumOfDays;
                                setXNumOfDays(spXNumOfDays);
                                break;

                            case 1: // Exchange
                                var exXNumOfDays = tenants.find(tenant => tenant.id === row.tenantId).exchangeGetXLastNumOfDays;
                                setXNumOfDays(exXNumOfDays);
                                break;

                            case 2: // Entra ID
                                var entraXNumOfDays = tenants.find(tenant => tenant.id === row.tenantId).entraIDGetXLastNumOfDays;
                                setXNumOfDays(entraXNumOfDays);
                                break;

                        }
                    }
                }
                catch (e) {
                    console.log("ERROR: handleOpen");
                    console.log(e);
                }
                finally {
                    setLoadingRow(false);
                }
            }

        };

        const handleToggleJobIsActive = async (val) => {

            const warningDate = new Date(new Date().getTime() - (1000 * 60 * 60 * 1000));

            if (Date.parse(row.lastSuccessfulRunEndTime) < Date.parse(warningDate)) {
                alert("Unable to mark job as active until Tenant Validation is complete.");
                return;
            }

            if (val) {
                setLoadingRow(true);
                try {
                    var isActive = !row.isActive;
                    var rsaKey = `{SysAdminId: "${await encryptKey(user.microsoftGraphId)}", JobId: "${await encryptKey(row.id.toString())}", IsActive: "${isActive.toString()}"}`;
                    if (rsaKey) {

                        var result = await axios.put(`${WEB_API_URL}AdminUtilities/UpdateJobIsActive`, null, {
                            params: {
                                key: rsaKey,
                            }
                        });

                        if (result) {
                            navigate(0);
                        }
                    }

                }
                catch (e) {
                    console.log("ERROR: handleToggleJobIsRunning");
                    console.log(e);
                }
                finally {
                    setLoadingRow(false);
                }
            }

        };

        const handleToggleJobIsRunning = async (val) => {

            const warningDate = new Date(new Date().getTime() - (1000 * 60 * 60 * 1000));

            if (Date.parse(row.lastSuccessfulRunEndTime) < Date.parse(warningDate)) {
                alert("Unable to mark job as running until Tenant Validation is complete.");
                return;
            }

            if (!row.isRunning) {
                alert("Unable to mark job as running.  Job Orceshtrator will perform this task.");
                return;
            }

            if (val) {
                setLoadingRow(true);
                try {

                    var rsaKey = `{SysAdminId: "${await encryptKey(user.microsoftGraphId)}", JobId: "${await encryptKey(row.id.toString())}", TenantId: "${await encryptKey(row.tenantId.toString())}"}`;
                    if (rsaKey) {

                        var result = await axios.put(`${WEB_API_URL}AdminUtilities/DisableJobIsRunning`, null, {
                            params: {
                                key: rsaKey,
                            }
                        });

                        if (result) {
                            navigate(0);
                        }
                    }
                }
                catch (e) {
                    console.log("ERROR: handleToggleJobIsRunning");
                    console.log(e);
                }
                finally {
                    setLoadingRow(false);
                }
            }

        };


        const handleUpdateTenantSetJobXNumDays = async () => {

            const warningDate = new Date(new Date().getTime() - (1000 * 60 * 60 * 1000));

            if (Date.parse(row.lastSuccessfulRunEndTime) < Date.parse(warningDate)) {
                alert("Unable to the days for reload until Tenant Validation is complete.");
                return;
            }

            setLoadingRow(true);

            if (xNumOfDays > 0) {
                alert("Once the job reload is complete, set the interval back to 0. \n\rThis process will run every time the job is run and could put unwanted stress on the system.");
            }

            try {

                var rsaKey = `{SysAdminId: "${await encryptKey(user.microsoftGraphId)}", JobId: "${row.id.toString()}",  XNumOfDays: "${xNumOfDays}", TenantId: "${row.tenantId.toString()}"}`;
                if (rsaKey) {

                    var result = await axios.put(`${WEB_API_URL}AdminUtilities/UpdateTenantSetJobToRunXNumOfDays`, null, {
                        params: {
                            key: rsaKey,
                        }
                    });

                    if (result) {
                        navigate(0);
                    }
                }
            }
            catch (e) {
                console.log("ERROR: handleUpdateTenantSetJobXNumDays");
                console.log(e);
            }
            finally {
                setLoadingRow(false);
            }
        };


        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell key={row.id + "-jobHealth"} component="th" scope="row">
                        {getJobHeath(row)}
                    </TableCell>
                    <TableCell key={row.id + "-isSuccessful"} component="th" scope="row">
                        {row.isLastRunSuccessful &&
                            <TaskAltIcon fontSize="small" sx={{ color: "green" }}></TaskAltIcon>
                        }
                        {!row.isLastRunSuccessful &&
                            <RadioButtonUncheckedIcon fontSize="small" sx={{ color: "red" }}></RadioButtonUncheckedIcon>
                        }
                    </TableCell>
                    <TableCell key={row.id + "-tenant"} component="th" scope="row">
                        {
                            tenants.find(tenant => tenant.id === row.tenantId) ? (tenants.find(tenant => tenant.id === row.tenantId)).tenantUrl : "N/A"
                        }
                    </TableCell>
                    <TableCell key={row.id + "-auditType"} align="left">{getAuditSource(row.auditTypeAssigned)}</TableCell>
                    <TableCell key={row.id + "-rowId"} align="left">{row.id}</TableCell>
                    <TableCell key={row.id + "-auditEntriesProcessed"} align="center">{row.auditEntriesProcessed}</TableCell>
                    <TableCell key={row.id + "-lastSuccessufulRunTime"} align="left">{formatDate(dayjs(row.lastSuccessfulRunEndTime))}</TableCell>
                    <TableCell key={row.id + "-lastSuccessfulTime"} align="left">{formatDate(dayjs(row.lastJobRunStartTime))}</TableCell>
                    <TableCell key={row.id + "-lastRunEndTime"} align="left">{formatDate(dayjs(row.lastJobRunEndTime))}</TableCell>
                    <TableCell key={row.id + "--isActive"} component="th" scope="row" align="center">
                        {row.isActive &&
                            <TaskAltIcon fontSize="small" sx={{ color: "green" }}></TaskAltIcon>
                        }
                        {!row.isActive &&
                            <RadioButtonUncheckedIcon fontSize="small" sx={{ color: "red" }}></RadioButtonUncheckedIcon>
                        }
                    </TableCell>
                    <TableCell key={row.id + "-seeDetails"} >
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleOpen(!open, row)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell key={row.id + "-rowDetails"} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Grid container spacing={0}>
                                    <Grid item xs={2}>
                                        <Typography>Company:</Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span"><b>{jobDetails.companyName}</b></Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>Tenant:</Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span"><b>{jobDetails.tenantUrl}</b></Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>Running:</Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span"><Switch checked={row.isRunning} onClick={() => handleToggleJobIsRunning(row)} /></Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>Active:</Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="span"><Switch checked={row.isActive} onClick={() => handleToggleJobIsActive(row)} /></Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>Reload Audit History:</Typography>
                                    </Grid>
                                    <Grid item xs={10}>

                                        Starting <Typography component="span">
                                            <TextField required
                                                select
                                                name="xNumOfDays"
                                                variant="outlined"
                                                size="small"
                                                style={{ width: 60 }}
                                                InputLabelProps={{ shrink: true }}
                                                value={xNumOfDays}
                                                onChange={event => setXNumOfDays(event.target.value)}
                                            >
                                                <MenuItem key="0" value={0}>0</MenuItem>
                                                {xNumOfDayVals.map((value, index) => (
                                                    <MenuItem key={index} value={value}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </TextField> day(s) ago. <Button size="small" onClick={() => handleUpdateTenantSetJobXNumDays()}>Update</Button>

                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography><br></br></Typography>
                                <Typography gutterBottom component="div">
                                    <b>Job History for the Past Month:</b>
                                </Typography>
                                <ProgressBar loading={loadingRow} ></ProgressBar>
                                <TableContainer component={Paper} >
                                    <Table aria-label="JobHistoryTableDetails" sx={{ minWidth: 800 }}></Table>
                                    <Table size="small" aria-label="job-details">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell key="SPHeaderSucess2">Successful</TableCell>
                                                <TableCell key="SPHeaderItems2">Items</TableCell>
                                                <TableCell key="SPHeaderM65Day2">Last Successful Time</TableCell>
                                                <TableCell key="SPHeaderJobStart2">Job Start Time</TableCell>
                                                <TableCell key="SPHeaderJobEnd2">Job End Time</TableCell>
                                                <TableCell key="SPHeaderOrchestratorId">Orchestrator</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {jobHistory.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2).map((historyRow) => (
                                                <TableRow key={historyRow.id}>
                                                    <TableCell key={historyRow.id + "-isSuccessful"} component="th" scope="row">
                                                        {historyRow.isLastRunSuccessful &&
                                                            <TaskAltIcon fontSize="small" sx={{ color: "green" }}></TaskAltIcon>
                                                        }
                                                        {!historyRow.isLastRunSuccessful &&
                                                            <RadioButtonUncheckedIcon fontSize="small" sx={{ color: "red" }}></RadioButtonUncheckedIcon>
                                                        }
                                                    </TableCell>
                                                    <TableCell key={historyRow.id + "-entries"}>{historyRow.auditEntriesProcessed}</TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {formatDate(dayjs(historyRow.lastRunSuccessfulEndTime))}
                                                    </TableCell>
                                                    <TableCell key={historyRow.id + "-lastRunStartTime"} component="th" scope="row">
                                                        {formatDate(dayjs(historyRow.lastJobRunStartTime))}
                                                    </TableCell>
                                                    <TableCell key={historyRow.id + "-lastRunEndTime"} component="th" scope="row">
                                                        {formatDate(dayjs(historyRow.lastJobRunEndTime))}
                                                    </TableCell>
                                                    <TableCell align="center" key={historyRow.id + "-joId"}>{historyRow.jobOrchestratorId}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[25, 50, 100]}
                                        component="span"
                                        count={jobHistory.length}
                                        rowsPerPage={rowsPerPage2}
                                        page={page2}
                                        onPageChange={handleChangePage2}
                                        onRowsPerPageChange={handleChangeRowsPerPage2} />
                                </TableContainer>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment >
        );
    }

    Row.propTypes = {
        row: PropTypes.shape({
            id: PropTypes.number.isRequired,
            tenant: PropTypes.string.isRequired,
            auditTypeAssigned: PropTypes.string.isRequired,
            isLastRunSuccessful: PropTypes.bool.isRequired,
            auditEntriesProcessed: PropTypes.number.isRequired,
            lastSuccessfulRunEndTime: PropTypes.string.isRequired,
            lastJobRunStartTime: PropTypes.string.isRequired,
            lastJobRunEndTime: PropTypes.string.isRequired,
            isActive: PropTypes.bool.isRequired,
            history: PropTypes.arrayOf(
                PropTypes.shape({
                    amount: PropTypes.number.isRequired,
                    customerId: PropTypes.string.isRequired,
                    date: PropTypes.string.isRequired,
                }),
            ).isRequired,
        }).isRequired,
    };



    useEffect(() => {
        fetchData();
    }, []);

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>)
    }

    if (serviceIsDown) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />

            <TableContainer component={Paper} sx={{ maxWidth: 1380 }}>
                <Table aria-label="HistoricalAuditTable">
                    <TableHead>
                        <TableRow>
                            <TableCell key="SPHeaderHealth">Health</TableCell>
                            <TableCell key="SPHeaderSucess" onClick={() => requestSort("isLastRunSuccessful")}>Successful
                                {sortConfig && sortConfig.key === "isLastRunSuccessful" && (
                                    <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
                                )}
                            </TableCell>
                            <TableCell key="SPHeaderTenant" onClick={() => requestSort("tenant")}>Tenant
                                {sortConfig && sortConfig.key === "tenant" && (
                                    <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
                                )}
                            </TableCell>
                            <TableCell key="SPHeaderSource" onClick={() => requestSort("auditTypeAssigned")}>Source
                                {sortConfig && sortConfig.key === "auditTypeAssigned" && (
                                    <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
                                )}
                            </TableCell>
                            <TableCell key="SPHeaderId" onClick={() => requestSort("id")}>Id
                                {sortConfig && sortConfig.key === "id" && (
                                    <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
                                )}
                            </TableCell>
                            <TableCell key="SPHeaderItems" onClick={() => requestSort("auditEntriesProcessed")}>Items
                                {sortConfig && sortConfig.key === "auditEntriesProcessed" && (
                                    <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
                                )}
                            </TableCell>
                            <TableCell key="SPHeaderM65Day" onClick={() => requestSort("lastSuccessfulRunEndTime")}>Last Successful Time
                                {sortConfig && sortConfig.key === "lastSuccessfulRunEndTime" && (
                                    <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
                                )}
                            </TableCell>
                            <TableCell key="SPHeaderJobStart" onClick={() => requestSort("lastJobRunStartTime")}>Job Start Time
                                {sortConfig && sortConfig.key === "lastJobRunStartTime" && (
                                    <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
                                )}</TableCell>
                            <TableCell key="SPHeaderJobEnd" onClick={() => requestSort("lastJobRunEndTime")}>Job End Time
                                {sortConfig && sortConfig.key === "lastJobRunEndTime" && (
                                    <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
                                )}
                            </TableCell>
                            <TableCell key="SPHeaderActive" onClick={() => requestSort("isActive")}>Active
                                {sortConfig && sortConfig.key === "isActive" && (
                                    <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
                                )}
                            </TableCell>
                            <TableCell key="SPHeaderDetails">Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {jobs && stableSort(jobs, getComparator(sortConfig)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((job) => (
                            <Row key={job.id} row={job} />
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="span"
                    count={jobs.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage} />
            </TableContainer >
        </>
    );

}
export default SystemJobsList;