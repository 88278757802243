// React Components
import React, { useRef, useEffect, useState } from "react";
import axios from "axios";

// Material UI Components
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import AccessDenied from "../../components/common/AccessDenied";
import ProgressBar from "../../components/common/ProgressBar";
import DiagnosticsList from "../../components/DiagnosticsList";
import ServiceIsDown from "../../components/common/ServiceIsDown";
import DisplayBreadCrumb from "../../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../../components/common/DisplaySectionTitleAndDescription";

// Audit Vault Utilities
import { encryptKey } from '../../utilities/common-encrypt-util';
import { getDiagnosticLogsForLastXNumOfDaysAndMinLevel } from "../../utilities/common-tenant";

const theSectionTitle = "Report: System Diagnostic Report";
const theSectionDesc = "This report displays all the diagnostic logs that have occurred in the system.  Filter by dates, Diagnostic Level and Tenant.";
const isAdminPage = true;

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Admin', link: '/Admin/AdminHome' },
    { name: 'System Diagnostic Report', link: '/Admin/system-diagnostics' }
];

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

function AdminSystemDiagnosticsReport(props) {

    const { user } = props;


    // Component Constants
    const [logs, setLogs] = useState([]);

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);
    const [numOfDays, setNumOfDays] = useState(1);
    const [minDiagnosticLevel, setMinDiagnosticLevel] = useState(4);
    const [tenantId, setTenantId] = useState(0);
    const [tenants, setTenants] = useState([]);

    const tenantRef = useRef(0);
    const minDiagnosticLevelRef = useRef(4);
    const numOfDaysRef = useRef(1);

    const handleTenantChange = (val) => {
        setTenantId(val);
    };

    const handleDiagnosticLevelChange = (val) => {
        setMinDiagnosticLevel(val);
    };

    const handleNumOfDaysChange = (val) => {
        setNumOfDays(val);
    };

    // Component Comments
    async function fetchData() {

        if (user) {

            if (user.isSystemAdmin) {
                try {
                    var data = {
                        sysAdminId: user.microsoftGraphId,
                    };
                    var keyString = await encryptKey(JSON.stringify(data));
                    if (keyString) {
                        var result = await axios.get(`${WEB_API_URL}AdminUtilities/GetTenants`, {
                            params: {
                                key: keyString,
                            }
                        });
                        if (result) {
                            setTenants(result.data);
                            getDiagnosticLogs();
                        }
                    }
                } catch (e) {
                    setAccessDenied(true);
                    console.log("ERROR: System Disagnostic Logs")
                    console.log(e);
                }
                finally {
                    setLoading(false);
                }
            }
            else {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    async function getDiagnosticLogs() {
        if (user) {

            if (user.isSystemAdmin) {
                try {
                    setLoading(true);
                    var response = await getDiagnosticLogsForLastXNumOfDaysAndMinLevel(tenantId, user.microsoftGraphId, numOfDays, minDiagnosticLevel);
                    if (response) {
                        setLogs(response);
                    }
                } catch (e) {
                    setAccessDenied(true);
                    console.log("ERROR: System Disagnostic Logs")
                    console.log(e);
                }
                finally {
                    setLoading(false);
                }
            }
        }
    }

    // Component UI
    if (loading) {
        return <ProgressBar message="Loading ..." loading={loading} />
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (accessDenied) {
        return (<><AccessDenied></AccessDenied></>);
    }

    if (!loading) {
        return (
            <React.Fragment>
                <DisplayBreadCrumb paths={theBreadcrumbPath} />
                <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />

                {logs &&
                    <><Card sx={{ width: '50%', minWidth: 550 }} variant="outlined">
                        <CardContent>
                            <Typography component="div"><b>Search for Diagnostic Logs:</b></Typography>
                            <Typography component="div"><br></br></Typography>
                            <Table sx={{
                                minWidth: 500, width: '100%', [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none"
                                }
                            }} size="small">
                                <TableHead>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell key="search-desc"><b>Tenant:</b></TableCell>
                                        <TableCell key="search-barcell">
                                            <TextField required
                                                select
                                                name="tenantId"
                                                inputRef={tenantRef}
                                                value={tenantId}
                                                variant="outlined"
                                                size="small"
                                                style={{ width: 300 }}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={event => handleTenantChange(event.target.value)}
                                            >
                                                <MenuItem key={0} value="0">
                                                    ALL
                                                </MenuItem>
                                                {tenants.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.tenantUrl}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell key="tenantSerch"><b>Minimum Diagnostic Level:</b></TableCell>
                                        <TableCell key="tenantSearchTxtbox">
                                            <TextField required
                                                select
                                                name="minDiagnosticLevel"
                                                inputRef={minDiagnosticLevelRef}
                                                value={minDiagnosticLevel}
                                                variant="outlined"
                                                size="small"
                                                style={{ width: 300 }}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={event => handleDiagnosticLevelChange(event.target.value)}
                                            >
                                                <MenuItem key="mnuDiagnostic5" value="5">
                                                    ALL
                                                </MenuItem>
                                                <MenuItem key="mnuDiagnostic4" value="4">
                                                    Information
                                                </MenuItem>
                                                <MenuItem key="mnuDiagnostic3" value="3">
                                                    Warning
                                                </MenuItem>
                                                <MenuItem key="mnuDiagnostic2" value="2">
                                                    Critical
                                                </MenuItem>
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell key="flterRole"><b>Duration:</b></TableCell>
                                        <TableCell key="filterRole-txt">
                                            <TextField required
                                                select
                                                name="numOfDays"
                                                inputRef={numOfDaysRef}
                                                value={numOfDays}
                                                variant="outlined"
                                                size="small"
                                                style={{ width: 300 }}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={event => handleNumOfDaysChange(event.target.value)}
                                            >
                                                <MenuItem key="mnuDiagnostic5" value="1">
                                                    Last 24 Hours
                                                </MenuItem>
                                                <MenuItem key="mnuDiagnostic5" value="7">
                                                    Last 7 Days
                                                </MenuItem>
                                                <MenuItem key="mnuDiagnostic4" value="30">
                                                    Last 30 Days
                                                </MenuItem>
                                                <MenuItem key="mnuDiagnostic3" value="60">
                                                    Last 60 Days
                                                </MenuItem>
                                                <MenuItem key="mnuDiagnostic2" value="90">
                                                    Last 90 Days
                                                </MenuItem>
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                        </CardContent>
                        <CardActions>
                            <Button type="submit" aria-label="search" onClick={() => getDiagnosticLogs()}>Search <SearchIcon sx={{ paddingLeft: '5px' }} /></Button>
                        </CardActions>
                    </Card>
                    </>
                }
                <Typography component="div"><br></br></Typography>
                {logs &&
                    <DiagnosticsList
                        user={user}
                        logs={logs}
                        getDiagnosticLogs={getDiagnosticLogs}
                    ></DiagnosticsList>
                }

            </React.Fragment >
        );
    }


}
export default AdminSystemDiagnosticsReport;