// React Components
import * as React from 'react';
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';

// Material UI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import AccessDenied from '../common/AccessDenied';
import DisplayBreadCrumb from "../../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../../components/common/DisplaySectionTitleAndDescription";
import ServiceIsDown from '../../components/common/ServiceIsDown';
import ProgressBar from '../../components/common/ProgressBar';
import UsersList from "../../components/common/UsersList";

// Audit Vault Utilities
import getRole from '../../utilities/common-role-utils';

import { verifyUserHasTenantRolePermissions, getTenantUsersByRole, getAuditVaultUsersCompanyAdminsByCompanyId } from '../../utilities/common-user-utils';
import { getCompanyByCompanyId } from '../../utilities/common-company';
import { ROLE_CODE_TENANTADMINISTRATOR } from '../../constants/constants-roles';

// Component Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

//const rows = [];

//const columns = [
//    { id: 'chk', label: ' ', minWidth: 1, align: 'left' },
//    { id: 'displayName', label: 'Display Name', minWidth: 200, align: 'left' },
    //{ id: 'userId', label: 'User Id', minWidth: 200,  align: 'left' },
//  ];

  
  // Section titles.   
  const theSectionTitle = "Role Assignments";
  const theSectionDesc = "Review details about this role group, what access it provides and assign members from your organization to the chosen role.";
  const isAdminPage = false;

  const companyAdminGeneralDescription = "Assign the Company Administrator role to users who need access to all permissions, features and data across the application.  Giving too many users Company Administrator access is a security risk.  We recommend that you have between 2 and 4 Company Administrators.";
  const tenantAdminGeneralDescription = "Assign the Tenant Administrator role to users who need access to administer the day to day operations of the application.  This includes granting uers access, running reports and reviewing the status of the application.  We recommend that you have between 2 and 4 Tenant Administrators.";
  const tenantReportReaderGeneralDescription = "Assign the Tenant Report Reader role to users who need access to run and view reports, insight searches, and view audit information for the specified Tenant.";
  const tenantViewerGeneralDescription = "Assign the Tenant Viewer role to users who need access to view App status, and can be used to manage access to SharePoint View Item Audit History Page for the Tenant.";

  const companyAdminNote = "The person who signed up for this service was automatically assigned as a Company Administrator.  You should evaluate to ensure that person should have that level of access.";

export default function TenantRoleForm(props) {

    const { user } = props;

    const { tenantId, roleId } = useParams();

    const navigate = useNavigate();
    const [value, setValue] = useState(0);

    const [company, setCompany] = useState("");
    const [loading, setLoading] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    const [users, setUsers] = useState([]);

    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Tenant', link: '/Tenant/MyTenant' },
        { name: `Role Assignments : ${getRole(Number(roleId))}`, link: `/mytenant-roles/${tenantId}/${roleId}` },
    ];

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    async function fetchRoleData() {
        
        if (user)
        {
            var companyResponse = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
            if (companyResponse)
            {
                setCompany(companyResponse);
                if (verifyUserHasTenantRolePermissions(user, tenantId, ROLE_CODE_TENANTADMINISTRATOR))
                {
                    try{
                        setLoading(true);
                        if (roleId > 1)
                        {
                            var response = await getTenantUsersByRole(tenantId, roleId);
                            if (response)
                            {
                                console.log(response);
                                setUsers(response);
                            }
                        }
                        if (roleId == 1)
                        {
                            
                            var response = await getAuditVaultUsersCompanyAdminsByCompanyId(user.companyId); 
                            if (response)
                            {
                                console.log(response)
                                setUsers(response);
                            }
                        }

                    }
                    catch(e)
                    {
                        console.log("ERROR: TenantRoleForm.fetchRoleData");
                        console.log(e);
                        setServiceIsDownError(true);
                    }
                    finally{
                        setLoading(false);
                    }
                }
                else
                {
                    setLoading(false);
                    setAccessDenied(true);
                }
            }
        }
    }

    useEffect(() => {
        fetchRoleData();
      }, []);

    function getTenantUrlFromUserPerms()
    {
        if (user && company)
        {
            for (var i = 0; i < company.tenantList.length; i++)
            {
                if (company.tenantList[i].tenantId = tenantId)
                {
                    return company.tenantList[i].tenantUrl;
                }
            }
        }
    }

    
    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue == 1) {
            if (users == null || users.length == 0)
            {
                fetchRoleData();
            }
        }
    };

    // UI of Component
    if (loading) {
        return <ProgressBar message="Loading ..." loading={loading} />
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (!loading)
    {
    return (
        <>  
        <DisplayBreadCrumb paths={theBreadcrumbPath} />
        <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
        <Typography component="div" sx={{paddingBottom: 3}}>
            <Typography component="span" sx={{paddingRight: 3}}><b>For Tenant:</b></Typography>
            <Typography component="span">{getTenantUrlFromUserPerms()}</Typography>
        </Typography>
        <b>{getRole(Number(roleId))}</b>
        <hr />
        
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="General" {...a11yProps(0)} />
                    <Tab label="Assigned Members" {...a11yProps(1)} />
                    <Tab label="Permissions" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
            <Typography><b>Who should be assigned to this role?</b><br /><br /></Typography>
                <Typography component="span">
                    {roleId == 1 && 
                        companyAdminGeneralDescription
                    }
                    {roleId == 2 &&
                        tenantAdminGeneralDescription
                    }
                    {roleId == 3 &&
                        tenantReportReaderGeneralDescription
                    }
                    {roleId == 4 && 
                        tenantViewerGeneralDescription
                    }
                    {roleId == 1 &&
                        <>
                         <Typography><br></br></Typography>
                         <Typography><b>Note:</b></Typography>
                         <Typography>{companyAdminNote}</Typography>
                        </>
                    }
                </Typography>
                <Typography><br></br></Typography>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Typography component="span">
                {!accessDenied && users &&
                    <>
                    <b>Select Remove option to remove a user from this role group.  Click Add Users to add additional users to this role assignment.</b>
                    <br /><br />
                    <UsersList users={users} tenantId={tenantId} fetchRoleData={fetchRoleData} ></UsersList>
                    
                    <Typography><br></br></Typography>

                    <Button variant="contained" size="small" onClick={() => navigate(`/mytenant-roles-assignusers/${tenantId}/${roleId}`)}>Assign User to {getRole(Number(roleId))} Role</Button>
                    </>
                }
                {accessDenied &&
                    <AccessDenied></AccessDenied>
                }
                </Typography>
            </TabPanel>
            <TabPanel value={value} index={2}>
            <Typography><b>Members of this Role have the following permissions:</b></Typography> 
            <Typography><br /></Typography>
            <Box sx={{ width: '100%', maxWidth: 850, bgcolor: 'background.paper' }}>
            <nav aria-label="main mailbox folders">
            <List>
            <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="Log Into Home Screen" />
                </ListItem>
            {roleId == 1 && 
                <>
                <Typography><br></br></Typography>
                <Divider />
                <ListItem disablePadding>
                <Typography><b>Company:</b></Typography>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage Company Settings" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage Users" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage Role Asssignments" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage Billing" />
                </ListItem>
                <Typography><br></br></Typography>
                <Divider />
                <ListItem disablePadding>
                <Typography><b>For All Tenants:</b></Typography>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage Tenant Settings" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="View and Run Reports" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="View, Run, Manage and Delete Insight Searches for any Tenant" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="View Tenant Status, Audit Information, and access to SharePoint View Item Audit History Page for the Tenant" />
                </ListItem>
                
                </>
            }

            {roleId == 2 && 
                <>
                <Typography><br></br></Typography>
                <Divider />
                <ListItem disablePadding>
                <Typography><b>For the Specific Tenant:</b></Typography>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage Tenant Role Assignments" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage Tenant Settings" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="View and Run Reports" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="View, Run, Manage and Delete any Insight Searches belonging to Tenant" />                    
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="View Tenant Status, Audit Information, and access to SharePoint View Item Audit History Page for the Tenant" />
                </ListItem>
                
                </>
            }

            {roleId == 3 && 
                <>
                <Typography><br></br></Typography>
                <Divider />
                <ListItem disablePadding>
                <Typography><b>For the Specific Tenant:</b></Typography>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="View and Run Reports" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="View, Run, Manage and Delete your own Insight Searches" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="View Tenant Status, Audit Information, and access to SharePoint View Item Audit History Page for the Tenant" />
                </ListItem>
                
                </>
            }

            {roleId == 4 && 
                <>
                <Typography><br></br></Typography>
                <Divider />
                <ListItem disablePadding>
                <Typography><b>For the Specific Tenant:</b></Typography>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="View Tenant Status, and option for access to the SharePoint View Item Audit History Page for the Tenant" />                    
                </ListItem>
                </>
            }

            <Typography><br></br></Typography>
                <Divider />
                <ListItem disablePadding>
                <Typography><b>Health and Support:</b></Typography>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="View Message Centre" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="View Help Centre" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="Access to Product Feedback Form" />
                </ListItem>
            </List>
            </nav>
            </Box>
            
            </TabPanel>
        </Box>
        </>
      );
        };
}