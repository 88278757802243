// React Components
import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery,  useTheme} from "@mui/material";

// Material UI Components
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import SupportAgent from '@mui/icons-material/SupportAgent';
import Chat from '@mui/icons-material/Chat';
import MailOutline from '@mui/icons-material/MailOutline';
import MailIcon from '@mui/icons-material/Mail';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SettingsIcon from '@mui/icons-material/Settings';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import PolicyIcon from '@mui/icons-material/Policy';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import MainAppBar from "./MainAppBar";
import LogoSmall from '../imgs/logoSmall.svg';
import MobileMenu from "./MobileMenu";

const drawerWidth = 240;
const AuditValultVer = process.env.REACT_APP_AUDIT_VAULT_VERSION;

export const PageLayout = (props) => {

    const { user, setUser, isAuthenticated, userRole } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [openMenu2, setOpenMenu2] = React.useState(false);  // Menu 2 - Company.
    const [openMenu3, setOpenMenu3] = React.useState(false);  // Menu 3 - Tenant.
    const [openMenu4, setOpenMenu4] = React.useState(false);  // Menu 4 - Reports.
    const [openMenu5, setOpenMenu5] = React.useState(false);  // Menu 5 - Support.
    const [openMenu6, setOpenMenu6] = React.useState(false);  // Menu 6 - Admin.

    const handleClickMenu2 = () => {
        setOpenMenu2(!openMenu2);
      };

    const handleClickMenu3 = () => {
        setOpenMenu3(!openMenu3);
      };

    const handleClickMenu4 = () => {
        setOpenMenu4(!openMenu4);
      };

    const handleClickMenu5 = () => {
        setOpenMenu5(!openMenu5);
      };

    const handleClickMenu6 = () => {
        setOpenMenu6(!openMenu6);
      };

    /*
        SystemAdministrator = 0,
        CompanyAdministrator = 1,
        TenantAdministrator = 2,
        TenantReportReader = 3,
        TenantViewer = 4,
        NoAccess = 5,
    */
    return (
        <>
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {isMobile ? (
          <MobileMenu user={user} isAuthenticated={isAuthenticated} userRole={userRole}/>
        ) : (
        <>
        <MainAppBar setUser={setUser} />
        <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <AppBar position="static">
                    <Toolbar>
                        <a href="https://ecminsights.com"><img src={LogoSmall} alt="ECM Insights" /></a>
                    </Toolbar>
                </AppBar>

                <Divider />
                <List>
                    <ListItem key="Home" disablePadding>
                        <ListItemButton component={Link} to="/">
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItemButton>
                    </ListItem>

                    {(() => {

                        // Company Admin Access Only
                        if ((isAuthenticated && userRole <= 1 && userRole > 0) || (user.isSystemAdmin && user.companyId > 0)) {
                            return (<>
                                <ListItem key="Company" disablePadding>
                                    <ListItemButton component={Link} to="/MyCompany" onClick={handleClickMenu2}>
                                        <ListItemIcon>
                                            <BusinessIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Company" />
                                        {openMenu2 ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </ListItem>
                                <Collapse in={openMenu2} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton sx={{ pl: 4 }} component={Link} to="/mycompany-roles">
                                            <ListItemIcon>
                                                <PeopleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Company Roles" />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }} component={Link} to="/mycompany-users">
                                            <ListItemIcon>
                                                <PersonSearchIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Company Users" />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }} component={Link} to="/mybilling">
                                            <ListItemIcon>
                                                <ReceiptIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Billing" />
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            </>
                            );
                        }
                        return null;
                    })()}

                    {(() => {

                        // Tenant Admin Access Only
                        if ((isAuthenticated && userRole <= 2 && userRole > 0) || (user.isSystemAdmin && user.companyId > 0)) {
                            return (
                                <>
                                    <ListItem key="Tenant" disablePadding>
                                        <ListItemButton component={Link} to="/Tenant/MyTenant?tab=0" onClick={handleClickMenu3}>
                                            <ListItemIcon>
                                                <CloudSyncIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Tenant" />
                                            {openMenu3 ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                    </ListItem>
                                    <Collapse in={openMenu3} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Tenant/MyTenant?tab=1">
                                                <ListItemIcon>
                                                    <PeopleIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Roles" />
                                            </ListItemButton>
                                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Tenant/MyTenant?tab=2">
                                                <ListItemIcon>
                                                    <AutoStoriesIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="SharePoint App" />
                                            </ListItemButton>
                                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Tenant/MyTenant?tab=3">
                                                <ListItemIcon>
                                                    <SettingsIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Jobs" />
                                            </ListItemButton>
                                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Tenant/MyTenant?tab=4">
                                                <ListItemIcon>
                                                    <WorkHistoryIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="History" />
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                </>
                            );
                        }
                        return null;

                    })()}


                    {(() => {

                        // Tenant Report Viewer Access Only
                        if ((isAuthenticated && userRole <= 3 && userRole > 0) || (user.isSystemAdmin && user.companyId > 0)) {
                            return (<>

                                <ListItem key="Reports" disablePadding>
                                    <ListItemButton component={Link} to="/Reports/ReportsHome" onClick={handleClickMenu4}>
                                        <ListItemIcon>
                                            <AssessmentIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Reports" />
                                        {openMenu4 ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </ListItem>
                                <Collapse in={openMenu4} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton sx={{ pl: 4 }} component={Link} to="/Reports/SharePoint">                                            
                                            <ListItemIcon>
                                            <svg width="30" height="30" fill="#666666" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m22 13.25q0 1.04-.4 1.95-.39.9-1.07 1.58-.68.68-1.59 1.08-.91.39-1.94.39-.64 0-1.27-.16-.09.83-.46 1.54-.38.72-.97 1.25-.58.53-1.33.82-.76.3-1.59.3-.91 0-1.71-.35-.79-.34-1.39-.93-.59-.59-.93-1.39-.35-.8-.35-1.7v-.32q.03-.15.05-.31h-4.22q-.33 0-.59-.24-.24-.26-.24-.59v-8.34q0-.33.24-.59.26-.24.59-.24h2.95q.12-1.06.61-2 .48-.89 1.24-1.56.75-.68 1.71-1.06.96-.38 2.04-.38 1.16 0 2.18.44 1.03.45 1.79 1.21.76.76 1.21 1.79.44 1.02.44 2.19v.31q0 .15-.04.31 1.04 0 1.95.39.92.39 1.59 1.07.71.67 1.1 1.58.4.92.4 1.96m-10.63-10q-.81 0-1.54.29-.74.28-1.33.78-.56.5-.95 1.18-.38.7-.5 1.5h4.12q.33 0 .59.25.24.25.24.58v4.12l.18-.03q.17-.62.5-1.18.32-.57.79-1.02.45-.45 1.03-.79.54-.33 1.17-.5.08-.43.08-.8 0-.91-.35-1.71-.34-.79-.94-1.38-.59-.59-1.38-.94-.8-.35-1.71-.35m-4.27 12q.45 0 .9-.09.42-.09.76-.31.34-.21.55-.56.19-.36.19-.88 0-.53-.2-.88-.22-.35-.55-.59-.33-.23-.71-.38l-.72-.29q-.32-.13-.55-.27-.22-.15-.22-.38 0-.16.12-.27.12-.1.28-.15.16-.07.33-.09.18-.01.3-.01.49 0 .88.12.38.13.79.38v-1.48q-.25-.07-.44-.12-.2-.05-.39-.08-.2-.03-.42-.05-.2-.01-.45-.01-.43 0-.88.09-.45.1-.82.32-.35.23-.59.56-.23.35-.23.86 0 .5.22.81.22.35.55.59.33.23.7.41l.72.29q.34.14.56.29.22.16.22.38 0 .19-.11.3-.1.11-.26.16-.13.08-.32.08h-.31q-.59 0-1.04-.18-.46-.2-.92-.52v1.56q.99.39 2.06.39m4.27 5.5q.63 0 1.21-.25.57-.24 1-.66.42-.43.67-1 .25-.57.25-1.21 0-.56-.19-1.07-.18-.51-.51-.91-.34-.41-.8-.69-.45-.28-1-.4v1.61q0 .33-.24.59-.26.24-.59.24h-2.86q-.06.31-.06.63 0 .64.25 1.21.24.57.66 1 .43.42 1 .66.57.25 1.21.25m5.63-3.75q.77 0 1.45-.29.69-.29 1.2-.81.51-.51.81-1.19.29-.68.29-1.46 0-.75-.29-1.45-.3-.69-.81-1.2-.51-.51-1.2-.81-.68-.29-1.45-.29-.77 0-1.45.3-.68.3-1.19.81-.51.51-.81 1.19-.3.7-.3 1.45v.21l.02.22q.46.22.85.54.38.32.7.71.3.4.52.86.21.46.32.95.66.26 1.34.26z"/></svg>
                                            </ListItemIcon>
                                            <ListItemText primary="SharePoint" />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }} component={Link} to="/Reports/Exchange">
                                            <ListItemIcon>
                                            <svg fill="#666666" width="24" height="24" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg" ><path d="M24 7.875q0 .293-.117.58t-.317.486L20.496 12l3.07 3.059q.2.199.317.486.117.287.117.58V21q0 .316-.117.586-.117.27-.322.475-.206.205-.475.322-.27.117-.586.117h-4.875q-.293 0-.58-.117t-.486-.317l-3.059-3.07-3.059 3.07q-.199.2-.486.317-.287.117-.58.117H4.5q-.316 0-.586-.117-.27-.117-.475-.322-.205-.206-.322-.475Q3 21.316 3 21v-3H.996q-.41 0-.703-.293T0 17.004V6.996q0-.41.293-.703T.996 6H3V3q0-.316.117-.586.117-.27.322-.475.206-.205.475-.322.27-.117.586-.117h4.875q.293 0 .58.117t.486.317l3.059 3.07 3.059-3.07q.199-.2.486-.317.287-.117.58-.117H22.5q.316 0 .586.117.27.117.475.322.205.206.322.475Q24 2.684 24 3zM4.5 3v3h6.504q.41 0 .703.293t.293.703V5.625L9.375 3zM3.375 15.938h5.25v-1.583h-3.41v-1.593h3.047V11.18H5.215V9.656H8.46V8.062H3.375zm19.125.187L19.875 13.5h-3.691q-.247 0-.463.094-.217.094-.375.252-.159.158-.252.375-.094.216-.094.463v3.691L17.625 21H22.5zm0-8.25V3h-4.875L13.5 7.125v2.191q0 .774-.404 1.424-.405.65-1.096.99v5.274q0 .41-.293.703t-.703.293H4.5v3h4.875l4.125-4.125v-2.191q0-.563.21-1.05.212-.486.575-.849t.85-.574Q15.62 12 16.184 12h2.191Z"/></svg>
                                            </ListItemIcon>
                                            <ListItemText primary="Exchange" />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }} component={Link} to="/Reports/EntraID">
                                            <ListItemIcon>
                                            <svg width="26" height="26" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_98_3132)">
                                            <g clip-path="url(#clip1_98_3132)">
                                            <rect x="12.05" y="0.05" width="71.9" height="95.9" rx="3.95" stroke="white" stroke-width="0.1"/>
                                            <rect x="0.05" y="83.95" width="71.9" height="95.9" rx="3.95" transform="rotate(-90 0.05 83.95)" stroke="white" stroke-width="0.1"/>
                                            <rect x="6.05" y="89.95" width="83.9" height="83.9" rx="3.95" transform="rotate(-90 6.05 89.95)" stroke="white" stroke-width="0.1"/>
                                            <circle cx="48" cy="48" r="23.95" stroke="white" stroke-width="0.1"/>
                                            <circle cx="48" cy="48" r="47.95" stroke="white" stroke-width="0.1"/>
                                            <path d="M48 0V96" stroke="white" stroke-width="0.1"/>
                                            <path d="M96 47.9999L0 47.9999" stroke="white" stroke-width="0.1"/>
                                            <path d="M0 6.10352e-05L96 96.0001" stroke="white" stroke-width="0.1"/>
                                            <path d="M0 96.0001L96 5.62289e-05" stroke="white" stroke-width="0.1"/>
                                            <path d="M25.6416 90.4728C27.0797 90.4644 28.4659 90.1346 29.4945 89.5368L59.8103 71.5187C63.2057 69.563 65.9594 65.2917 65.9594 61.976V33.5368C65.9594 26.8761 64.5079 20.922 62.0167 16.4448C73.5834 19.878 81.7427 28.1929 81.7427 37.908V84.1749C81.7427 87.6859 78.0767 90.5367 73.5439 90.5367H26.8083C26.4122 90.5367 26.0227 90.5149 25.6416 90.4728Z" stroke="#666666" stroke-width="4"/>
                                            <path d="M18.6128 64.2749C19.261 64.091 19.9526 63.7767 20.6707 63.3246L37.1189 53.216C41.7761 50.3538 44.6384 45.1107 44.6384 39.4421V22.9964C44.6384 17.5879 43.7219 13.256 42.1194 10.3192C42.1754 10.2866 42.2315 10.2542 42.2876 10.2218C55.3597 2.69704 65.9603 15.5367 65.9603 33.5368V61.976C65.9603 65.2917 63.2066 69.563 59.8111 71.5187L29.4954 89.5368C26.0538 91.5368 18.6104 90.5368 18.6104 84.5368L18.6128 64.2749Z" stroke="#666666" stroke-width="4"/>
                                            <path d="M33.2387 6.50703C23.3322 10.1545 14.0049 26.5433 14.0049 44.4012L14 60.4046C14 64.3366 16.9871 65.6438 20.6705 63.3246L37.1187 53.216C41.7759 50.3538 44.6382 45.1107 44.6382 39.4421V22.9964C44.6382 10.7625 39.9484 4.03662 33.2387 6.50703Z" stroke="#666666" stroke-width="4"/>
                                            </g>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_98_3132">
                                            <rect width="96" height="96" fill="white"/>
                                            </clipPath>
                                            <clipPath id="clip1_98_3132">
                                            <rect width="96" height="96" fill="white"/>
                                            </clipPath>
                                            </defs>
                                            </svg>
                                            </ListItemIcon>
                                            <ListItemText primary="Entra ID" />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }} component={Link} to="/Reports/Teams">
                                            <ListItemIcon>
                                                <svg fill="#666666" width="24px" height="24px" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg"><path d="M20.625 8.127q-.55 0-1.025-.205-.475-.205-.832-.563-.358-.357-.563-.832Q18 6.053 18 5.502q0-.54.205-1.02t.563-.837q.357-.358.832-.563.474-.205 1.025-.205.54 0 1.02.205t.837.563q.358.357.563.837.205.48.205 1.02 0 .55-.205 1.025-.205.475-.563.832-.357.358-.837.563-.48.205-1.02.205zm0-3.75q-.469 0-.797.328-.328.328-.328.797 0 .469.328.797.328.328.797.328.469 0 .797-.328.328-.328.328-.797 0-.469-.328-.797-.328-.328-.797-.328zM24 10.002v5.578q0 .774-.293 1.46-.293.685-.803 1.194-.51.51-1.195.803-.686.293-1.459.293-.445 0-.908-.105-.463-.106-.85-.329-.293.95-.855 1.729-.563.78-1.319 1.336-.756.557-1.67.861-.914.305-1.898.305-1.148 0-2.162-.398-1.014-.399-1.805-1.102-.79-.703-1.312-1.664t-.674-2.086h-5.8q-.411 0-.704-.293T0 16.881V6.873q0-.41.293-.703t.703-.293h8.59q-.34-.715-.34-1.5 0-.727.275-1.365.276-.639.75-1.114.475-.474 1.114-.75.638-.275 1.365-.275t1.365.275q.639.276 1.114.75.474.475.75 1.114.275.638.275 1.365t-.275 1.365q-.276.639-.75 1.113-.475.475-1.114.75-.638.276-1.365.276-.188 0-.375-.024-.188-.023-.375-.058v1.078h10.875q.469 0 .797.328.328.328.328.797zM12.75 2.373q-.41 0-.78.158-.368.158-.638.434-.27.275-.428.639-.158.363-.158.773 0 .41.158.78.159.368.428.638.27.27.639.428.369.158.779.158.41 0 .773-.158.364-.159.64-.428.274-.27.433-.639.158-.369.158-.779 0-.41-.158-.773-.159-.364-.434-.64-.275-.275-.639-.433-.363-.158-.773-.158zM6.937 9.814h2.25V7.94H2.814v1.875h2.25v6h1.875zm10.313 7.313v-6.75H12v6.504q0 .41-.293.703t-.703.293H8.309q.152.809.556 1.5.405.691.985 1.19.58.497 1.318.779.738.281 1.582.281.926 0 1.746-.352.82-.351 1.436-.966.615-.616.966-1.43.352-.815.352-1.752zm5.25-1.547v-5.203h-3.75v6.855q.305.305.691.452.387.146.809.146.469 0 .879-.176.41-.175.715-.48.304-.305.48-.715t.176-.879Z"/></svg>
                                            </ListItemIcon>
                                            <ListItemText primary="Teams" />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }} component={Link} to="/Reports/InsightsSearch/All">
                                            <ListItemIcon>
                                                <ManageSearchIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Insights Search" />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }} component={Link} to="/Reports/ThreatIntelligence">
                                            <ListItemIcon>
                                                <PolicyIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Detect Threats" />
                                        </ListItemButton>                                        
                                    </List>
                                </Collapse>
                            </>
                            );
                        }
                        return null;
                    })()}

                    {(() => {

                        // Tenant Viewer or anyone who is logged in
                        if (isAuthenticated) {
                            return (
                                <>
                                    <ListItem key="Support" disablePadding>
                                        <ListItemButton component={Link} to="/Support/SupportHome" onClick={handleClickMenu5}>
                                            <ListItemIcon>
                                                <HelpCenterIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Support" />
                                            {openMenu5 ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                    </ListItem>
                                    <Collapse in={openMenu5} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Support/MessageCenter">
                                                <ListItemIcon>
                                                    <MailIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Message Center" />
                                            </ListItemButton>
                                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Support/ProductFeedback">
                                                <ListItemIcon>
                                                    <Chat />
                                                </ListItemIcon>
                                                <ListItemText primary="Product Feedback" />
                                            </ListItemButton>
                                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Support/SupportTicket">
                                                <ListItemIcon>
                                                    <SupportAgent />
                                                </ListItemIcon>
                                                <ListItemText primary="Support Ticket" />
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                </>
                            );
                        }
                        return null;
                    })()}

                    {(() => {
                        // System Admin Access Only

                        if (isAuthenticated && user.isSystemAdmin) {
                            return (
                                <>
                                    <ListItem key="Admin" disablePadding>
                                        <ListItemButton component={Link} to="/Admin/AdminHome" onClick={handleClickMenu6}>
                                            <ListItemIcon>
                                                <AdminPanelSettingsIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Admin" />
                                            {openMenu6 ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                    </ListItem>
                                    <Collapse in={openMenu6} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Admin/CompanyListAdmin">
                                                <ListItemIcon>
                                                    <ApartmentIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Company List" />
                                            </ListItemButton>
                                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Admin/MessageCenterAdmin">
                                                <ListItemIcon>
                                                    <MailOutline />
                                                </ListItemIcon>
                                                <ListItemText primary="Message Center" />
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                </>
                            );
                        }
                        return null;
                    })()}

                </List>
            </Drawer>
            </>
        )
    };
        <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, minWidth: '80%' }}
        >
        <Toolbar />
            {props.children && (props.children)}
            <Typography component="div" sx={{ textAlign: "right", width: "100%", fontSize: "8px", padding: "8px"}}>{AuditValultVer}</Typography>
        </Box>
        
        </Box>
        </>
    )
};