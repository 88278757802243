// React Components
import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";

// Audit Vault Pages
import { PageLayout } from "./components/PageLayout";
import TenantsAdmin from './pages/admin/TenantsAdmin';
import AddCompany from './pages/admin/AddCompany';
import MyProfile from './pages/MyProfile';
import MyCompany from './pages/MyCompany';
import UpdateCompany from "./pages/UpdateCompany";
import UpdateCompanyAdmin from "./pages/admin/UpdateCompanyAdmin";
import MyTenant from "./pages/MyTenant";
import UpdateTenant from "./pages/UpdateTenant";
import CreateTenant from "./pages/CreateTenant";
import CreateTenantAdmin from "./pages/admin/CreateTenantAdmin";
import MyTenantValidate from "./pages/MyTenantValidate";
import MyTenantStats from "./pages/MyTenantStats";
import GrantAccess from "./pages/GrantAccess";
import SupportHome from "./pages/SupportHome";
import MessageCenter from "./pages/MessageCenter";
import AdminHome from "./pages/AdminHome";
import CompanyListAdmin from './pages/admin/CompanyListAdmin';
import UpdateTenantAdmin from './pages/admin/UpdateTenantAdmin';
import MessageCenterAdmin from './pages/admin/MessageCenterAdmin';
import NewMessage from './pages/admin/NewMessage';
import EditMessage from './pages/admin/EditMessage';
import MyCompanyAssignRole from './pages/MyCompanyAssignRole';
import MyCompanyRoles from "./pages/MyCompanyRoles";
import MyTenantRoleForm from "./components/role/TenantRoleForm"
import TenantRoleAssignUserForm from "./components/role/TenantRoleAssignUserForm2";
import MyHome from "./pages/MyHome";
import MyBilling from "./pages/MyBilling";
import ProductFeedback from "./pages/ProductFeedback";
import SupportTicket from "./pages/SupportTicket";
import HelpCenter from "./pages/HelpCenter";
import SystemAdminUsersList from "./pages/admin/SystemAdminUsersList";
import SystemAdinAssignRole from "./pages/admin/SystemAdminAssignRole";
import MyCompanyUsers from "./pages/MyCompanyUsers";
import HeartbeatForSearch from "./pages/admin/HeartbeatForSearch";
import NewSearchOrchestrator from "./pages/admin/NewSearchOrchestrator";
import EditSearchOrchestrator from "./pages/admin/EditSearchOrchestrator";
import ViewSearchOrchestratorLogs from "./pages/admin/ViewSearchOrchestratorLogs";
import GrantHistoricalImportConsent from "./pages/GrantHistoricalImportConsent";
import MyTenantSetupHistoricalImport from "./pages/MyTenantSetupHistoricalImport";
import SystemEmailsList from "./pages/admin/SystemEmailsList";
import SystemEmailForm from "./pages/admin/SystemEmailForm";
import SystemJobsList from "./pages/admin/SystemJobsList";
import SystemOrchestratorsList from "./pages/admin/SystemOrchestratorsList";
import JobOrchestratorForm from "./pages/admin/JobOrchestratorForm";

// Company Setup
import MyCompanySetupCreate from "./pages/companysetup/MyCompanySetupCreate";
import MyCompanySetupUpdate from "./pages/companysetup/MyCompanySetupUpdate";
import MyCompanySetupComplete from "./pages/companysetup/MyCompanySetupComplete";

// Report related pages.
import Reports from './pages/Reports';
import ReportsSharePointByListItemUniqueId from "./pages/ReportsSharePointByListItemUniqueId";
import ReportsDiagnostics from "./pages/ReportsDiagnostics";
import ReportsSharepoint from "./pages/ReportsSharepoint";
import ReportsExchange from "./pages/ReportsExchange";
import ReportsEntraID from "./pages/ReportsEntraID";
import ReportsSearch from "./pages/ReportsSearch";
import ReportsThreatIntelligence from "./pages/ReportsThreatIntelligence";
import ReportsSearchResultsCompleted from "./pages/ReportsSearchResultsCompleted";
import ReportsSharePointFileActivity from "./pages/ReportsSharePointFileActivity";
import ReportsSharePointFileActivityNewAndEdit from "./pages/ReportsSharePointFileActivityNewAndEdit";
import ReportsSharePointFileActivityDeletion from "./pages/ReportsSharePointFileActivityDeletion";
import ReportsSharePointFileActivityViewAndDownload from "./pages/ReportsSharePointFileActivityViewAndDownload";
import ReportsSharePointPageViews from "./pages/ReportsSharePointPageViews";
import ReportsFromSpoViewSharePointAuditHistory from "./pages/ReportsFromSpoViewSharePointAuditHistory";
import ReportsSharePointAllActivityByUser from "./pages/ReportsSharePointAllActivityByUser";
import ReportsSharePointAllActivityByIPAddress from "./pages/ReportsSharePointAllActivityByIPAddress";
import ReportsSharePointAllActivityBySite from "./pages/ReportsSharePointAllActivityBySite";
import ReportsSharePointDeletionsByUser from "./pages/ReportsSharePointDeletionsByUser";
import ReportsSharePointCreationsByUser from "./pages/ReportsSharePointCreationsByUser";
import ReportsSharePointEditsByUser from "./pages/ReportsSharePointEditsByUser";
import ReportsSharePointViewsByUser from "./pages/ReportsSharePointViewsByUser";
import ReportsExchangeAllActivityByUser from "./pages/ReportsExchangeAllActivityByUser";
import ReportsExchangeAllActivityByIPAddress from "./pages/ReportsExchangeAllActivityByIPAddress";
import ReportsExchangeDeletedItems from "./pages/ReportsExchangeDeletedItems";
import ReportsExchangeMailboxesCreated from "./pages/ReportsExchangeMailboxesCreated";
import ReportsExchangeMailboxesDeleted from "./pages/ReportsExchangeMailboxesDeleted";
import ReportsExchangeMailboxesCreatedAndDeletedByUser from "./pages/ReportsExchangeMailboxesCreatedAndDeletedByUser";
import ReportsEntraIDAllActivityByUser from "./pages/ReportsEntraIDAllActivityByUser";
import ReportsEntraIDAllActivityByIPAddress from "./pages/ReportsEntraIDAllActivityByIPAddress";
import ReportsEntraIDFailedLogins from "./pages/ReportsEntraIDFailedLogins";
import ReportsEntraIDSuccessfulLogins from "./pages/ReportsEntraIDSuccessfulLogins";
import ReportsEntraIDGroupAndUserActivity from "./pages/ReportsEntraIDGroupAndUserActivity";
import ReportsEntraIDGroupAndUserActivityByUser from "./pages/ReportsEntraIDGroupAndUserActivityByUser";
import ReportsThreatSharePointFileDownloads from "./pages/ReportsThreatSharePointFileDownloads";
import ReportsThreatSharePointFileAccesses from "./pages/ReportsThreatSharePointFileAccesses";
import ReportsThreatSharePointFileOrFolderDeletions from "./pages/ReportsThreatSharePointFileOrFolderDeletions";
import ReportsThreatSharePointPermsUpdateAddedToGroup from "./pages/ReportsThreatSharePointPermsUpdateAddedToGroup";
import ReportsThreatExchangeDeletedItems from "./pages/ReportsThreatExchangeDeletedItems";
import ReportsThreatEntraIDFailedLoginsInvalidPassword from "./pages/ReportsThreatEntraIDFailedLoginsInvalidPassword";
import ReportsThreatEntraIDFailedLoginsFailedMFA from "./pages/ReportsThreatEntraIDFailedLoginsFailedMFA";
import ReportsThreatEntraIDSuccessfulLoginsDifferentIPs from "./pages/ReportsThreatEntraIDSuccessfulLoginDifferentIPs";
import ReportsHistoricalAuditHistory from "./pages/ReportsHistoricalAuditHistory";
import ReportsFirstAuditImportHistory from "./pages/ReportsFirstAuditImportHistory";
import AdminSystemDiagnosticsReport from "./pages/admin/AdminSystemDiagnosticsReport";
import ReportsTeams from "./pages/ReportsTeams";
import ReportsTeamsAllActivity from "./pages/ReportsTeamsAllActivity";
import ReportsTeamsAllActivityByUser from "./pages/ReportsTeamsAllActivityByUser";
import ReportsTeamsAllActivityByIPAddress from "./pages/ReportsTeamsAllActivityByIPAddress";
import ReportsTeamsAllActivityByTeamOrChannel from "./pages/ReportsTeamsAllActivityByTeamOrChannel";

// Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

// Audit Vault Components
import ServiceIsDown from './components/common/ServiceIsDown';

// Audit Vault Common
import { getAuditVaultUser } from "./utilities/common-user-utils";
import { createSystemEvent } from "./utilities/common-sysEvents-util";
import { SYSEVENT_LOGIN } from "./constants/constants-systemEvents";



// Display page not found or access denied details.
function NotFound({ location }) {
    return (
        <>
            <Alert severity="error">
                <AlertTitle>
                    Sorry, Page Not Found
                    <br />
                    <br />It appears the page you are requesting is not found or you do not have permissions to access this page.
                    <br />The requested url: {location.pathname}
                </AlertTitle>
                <br />For permission inquiries: please raise a support ticket to your local Company Administrator.
                <br />If you believe you are seeing this page in error: please raise a regular support ticket.
            </Alert>
        </>
    );
}


function App() {

    // If PROD, disable all console.log messages
    if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
        console.log = function no_console() { };
    }

    const location = useLocation();
    const { accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [user, setUser] = useState("");
    const [userRole, setUserRole] = useState("");
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [openCompanyNag, setOpenCompanyNag] = useState(true);

    async function fetchAuditVaultUserData(accounts, isAuthenticated, user, setLoading, loading, setServiceIsDownError) {

        if (loading && isAuthenticated && user == '') {

            try {
                const response = await getAuditVaultUser(isAuthenticated, accounts[0]);
                if (response) {
                    setUser(response);
                    if (response.isSystemAdmin) {
                        setUserRole(0);
                    }
                    else if (response.isCompanyAdmin) {
                        setUserRole(1);
                    }
                    else {
                        if (response.permissions.length > 0)
                            setUserRole(response.permissions[0].roleId);
                        else
                            setUserRole(5);
                    }
                }
            }
            catch (err) {
                console.log("ERROR: App.js.fetchAuditVaultUserData")
                console.log(err);
                setServiceIsDownError(true);
            }
            finally {
                setLoading(false);
            }
        }

    }

    useEffect(() => {
        fetchAuditVaultUserData(accounts, isAuthenticated, user, setLoading, loading, setServiceIsDownError);
        createSystemEvent(user, SYSEVENT_LOGIN, "");
    }, [setUser, user]);

    if (user == '' && isAuthenticated) {
        fetchAuditVaultUserData(accounts, isAuthenticated, user, setLoading, loading, setServiceIsDownError);
    }

    /*
        SystemAdministrator = 0,
        CompanyAdministrator = 1,
        TenantAdministrator = 2,
        TenantReportReader = 3,
        TenantViewer = 4,
        NoAccess = 5,
    */

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    // Added to prevent user from being blank if we render the Route before we are finished loading the User.  
    // Once the user is non-blank and the loading is false, only then render the regular page routes.
    if (loading || !user) {
        // Display a loading indicator
        return (
            <PageLayout user={user}
                setUser={setUser}
                isAuthenticated={isAuthenticated}
                userRole={userRole}>

                <UnauthenticatedTemplate>
                    <Routes>
                        <Route path="/" element={<MyCompany openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                        <Route path="/mycompany" element={<MyCompany openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                        <Route path="/reports/sharepointaudithistory/:id" element={<ReportsFromSpoViewSharePointAuditHistory />} />
                        <Route path="/mycompanysetupcreate" element={<MyCompanySetupCreate />} />
                    </Routes>
                </UnauthenticatedTemplate>
            </PageLayout>
        );
    }

    return (
        <PageLayout user={user}
            setUser={setUser}
            isAuthenticated={isAuthenticated}
            userRole={userRole}>

            <AuthenticatedTemplate>
                <Routes>

                    {isAuthenticated && userRole <= 5 && (
                        // Default access.
                        <>
                            <Route path="/" element={<MyHome user={user} serviceIsDownError={serviceIsDownError} setServiceIsDownError={setServiceIsDownError} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/myhome" element={<MyHome user={user} serviceIsDownError={serviceIsDownError} setServiceIsDownError={setServiceIsDownError} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/myprofile" element={<MyProfile user={user} />} />
                            <Route path="/reports/sharepointaudithistory/:id" element={<ReportsFromSpoViewSharePointAuditHistory user={user} />} />
                            <Route path="/support/messagecenter" element={<MessageCenter user={user} />} />
                            <Route path="/support/supporthome" element={<SupportHome user={user} />} />
                            <Route path="/support/productfeedback" element={<ProductFeedback user={user} />} />
                            <Route path="/support/helpcenter" element={<HelpCenter user={user} />} />
                            <Route path="/support/supportticket" element={<SupportTicket user={user} />} />
                            <Route path="/tenant-create" element={<CreateTenant user={user} />} />
                            <Route path="/Tenant/MyTenant" element={<MyTenant user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/grantaccess" element={<GrantAccess user={user} />} />
                            <Route path="/grantHistoricalImportConsent" element={<GrantHistoricalImportConsent user={user} />} />
                            <Route path="/mycompanysetupcreate" element={<MyCompanySetupCreate user={user} />} />
                            <Route path="/mycompanysetupupdate" element={<MyCompanySetupUpdate user={user} setUser={setUser} />} />
                            <Route path="/mycompanysetupcomplete" element={<MyCompanySetupComplete user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} setUser={setUser} />} />
                        </>
                    )}

                    {isAuthenticated && userRole <= 3 && (
                        // Tenant Report Reader and Up.
                        <>
                            <Route path="/reports/reportshome" element={<Reports user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/reports/sharepoint" element={<ReportsSharepoint user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/reports/exchange" element={<ReportsExchange user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/reports/entraID" element={<ReportsEntraID user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/reports/teams" element={<ReportsTeams user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/reports/insightssearch/:defaultWorkload" element={<ReportsSearch user={user} />} />
                            <Route path="/reports/threatintelligence" element={<ReportsThreatIntelligence user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/reports/viewsearchresults/:searchJobId" element={<ReportsSearchResultsCompleted user={user} />} />
                            <Route path="/reports/sharepointfileactivitynewandeditreport/:tenantId/:periodToUse/:filterByColumn" element={<ReportsSharePointFileActivityNewAndEdit user={user} />} />                            
                            <Route path="/reports/sharepointfileactivitydeletionreport/:tenantId/:periodToUse/:filterByColumn" element={<ReportsSharePointFileActivityDeletion user={user} />} />                            
                            <Route path="/reports/sharepointfileactivityviewanddownloadreport/:tenantId/:periodToUse/:filterByColumn" element={<ReportsSharePointFileActivityViewAndDownload user={user} />} />                                                        
                            <Route path="/reports/sharepointfileactivityreport/:tenantId/:periodToUse/:filterByColumn" element={<ReportsSharePointFileActivity user={user} />} />
                            <Route path="/reports/sharepointpageviewsreport/:tenantId/:periodToUse/:filterByColumn" element={<ReportsSharePointPageViews user={user} />} />
                            <Route path="/reports/sharepointitemaudithistoryreport" element={<ReportsSharePointByListItemUniqueId user={user} />} />
                            <Route path="/reports/sharepointitemaudithistoryreport/:id" element={<ReportsSharePointByListItemUniqueId user={user} />} />
                            <Route path="/reports/sharepointallactivitybyuserreport/:tenantId" element={<ReportsSharePointAllActivityByUser user={user} />} />
                            <Route path="/reports/sharepointallactivitybyipaddressreport/:tenantId" element={<ReportsSharePointAllActivityByIPAddress user={user} />} />
                            <Route path="/reports/sharepointallactivitybysitereport/:tenantId" element={<ReportsSharePointAllActivityBySite user={user} />} />
                            <Route path="/reports/sharepointdeletionsbyuserreport/:tenantId" element={<ReportsSharePointDeletionsByUser user={user} />} />
                            <Route path="/reports/sharepointcreationsbyuserreport/:tenantId" element={<ReportsSharePointCreationsByUser user={user} />} />
                            <Route path="/reports/sharepointeditsbyuserreport/:tenantId" element={<ReportsSharePointEditsByUser user={user} />} />
                            <Route path="/reports/sharepointviewsbyuserreport/:tenantId" element={<ReportsSharePointViewsByUser user={user} />} />
                            <Route path="/reports/exchangeallactivitybyuserreport/:tenantId" element={<ReportsExchangeAllActivityByUser user={user} />} />
                            <Route path="/reports/exchangeallactivitybyipaddressreport/:tenantId" element={<ReportsExchangeAllActivityByIPAddress user={user} />} />
                            <Route path="/reports/exchangedeleteditemsreport/:tenantId/:periodToUse/:filterByColumn" element={<ReportsExchangeDeletedItems user={user} />} />
                            <Route path="/reports/exchangemailboxescreatedreport/:tenantId/:periodToUse/:filterByColumn" element={<ReportsExchangeMailboxesCreated user={user} />} />
                            <Route path="/reports/exchangemailboxesdeletedreport/:tenantId/:periodToUse/:filterByColumn" element={<ReportsExchangeMailboxesDeleted user={user} />} />
                            <Route path="/reports/exchangemailboxescreatedordeletedbyuserreport/:tenantId" element={<ReportsExchangeMailboxesCreatedAndDeletedByUser user={user} />} />
                            <Route path="/reports/entraidallactivitybyuserreport/:tenantId" element={<ReportsEntraIDAllActivityByUser user={user} />} />
                            <Route path="/reports/entraidallactivitybyipaddressreport/:tenantId" element={<ReportsEntraIDAllActivityByIPAddress user={user} />} />
                            <Route path="/reports/entraidfailedloginsreport/:tenantId/:periodToUse/:filterByColumn" element={<ReportsEntraIDFailedLogins user={user} />} />
                            <Route path="/reports/entraidsuccessfulloginsreport/:tenantId/:periodToUse/:filterByColumn" element={<ReportsEntraIDSuccessfulLogins user={user} />} />
                            <Route path="/reports/entraidgroupanduseractivityreport/:tenantId/:periodToUse/:filterByColumn" element={<ReportsEntraIDGroupAndUserActivity user={user} />} />
                            <Route path="/reports/entraidgroupanduseractivitybyuserreport/:tenantId" element={<ReportsEntraIDGroupAndUserActivityByUser user={user} />} />
                            <Route path="/reports/threatsharepointfiledownloadsreport/:tenantId" element={<ReportsThreatSharePointFileDownloads user={user} />} />
                            <Route path="/reports/threatsharepointfiledownloadsreport/:tenantId/:paramEndDate/:paramStartDate/:paramThresholdCount" element={<ReportsThreatSharePointFileDownloads user={user} />} />
                            <Route path="/reports/threatsharepointfileaccessesreport/:tenantId" element={<ReportsThreatSharePointFileAccesses user={user} />} />
                            <Route path="/reports/threatsharepointfileaccessesreport/:tenantId/:paramEndDate/:paramStartDate/:paramThresholdCount" element={<ReportsThreatSharePointFileAccesses user={user} />} />
                            <Route path="/reports/threatsharepointfileorfolderdeletionsreport/:tenantId" element={<ReportsThreatSharePointFileOrFolderDeletions user={user} />} />
                            <Route path="/reports/threatsharepointfileorfolderdeletionsreport/:tenantId/:paramEndDate/:paramStartDate/:paramThresholdCount" element={<ReportsThreatSharePointFileOrFolderDeletions user={user} />} />
                            <Route path="/reports/threatsharepointpermsupdateaddedtogroupreport/:tenantId" element={<ReportsThreatSharePointPermsUpdateAddedToGroup user={user} />} />
                            <Route path="/reports/threatsharepointpermsupdateaddedtogroupreport/:tenantId/:paramEndDate/:paramStartDate/:paramThresholdCount" element={<ReportsThreatSharePointPermsUpdateAddedToGroup user={user} />} />
                            <Route path="/reports/threatexchangedeleteditemsreport/:tenantId" element={<ReportsThreatExchangeDeletedItems user={user} />} />
                            <Route path="/reports/threatexchangedeleteditemsreport/:tenantId/:paramEndDate/:paramStartDate/:paramThresholdCount" element={<ReportsThreatExchangeDeletedItems user={user} />} />
                            <Route path="/reports/threatentraidfailedloginsinvalidpasswordreport/:tenantId" element={<ReportsThreatEntraIDFailedLoginsInvalidPassword user={user} />} />
                            <Route path="/reports/threatentraidfailedloginsinvalidpasswordreport/:tenantId/:paramEndDate/:paramStartDate/:paramThresholdCount" element={<ReportsThreatEntraIDFailedLoginsInvalidPassword user={user} />} />
                            <Route path="/reports/threatentraidfailedloginsfailedmfareport/:tenantId" element={<ReportsThreatEntraIDFailedLoginsFailedMFA user={user} />} />
                            <Route path="/reports/threatentraidfailedloginsfailedmfareport/:tenantId/:paramEndDate/:paramStartDate/:paramThresholdCount" element={<ReportsThreatEntraIDFailedLoginsFailedMFA user={user} />} />
                            <Route path="/reports/threatentraidsuccessfulloginsdifferentipsreport/:tenantId" element={<ReportsThreatEntraIDSuccessfulLoginsDifferentIPs user={user} />} />
                            <Route path="/reports/threatentraidsuccessfulloginsdifferentipsreport/:tenantId/:paramEndDate/:paramStartDate/:paramThresholdCount" element={<ReportsThreatEntraIDSuccessfulLoginsDifferentIPs user={user} />} />
                            <Route path="/reports/diagnosticsreport/:id" element={<ReportsDiagnostics user={user} />} />
                            <Route path="/reports/historical-audit-history/:tenantId" element={<ReportsHistoricalAuditHistory user={user} />} />
                            <Route path="/reports/first-audit-import/:tenantId" element={<ReportsFirstAuditImportHistory user={user} />} />
                            <Route path="/reports/teamsallactivityreport/:tenantId/:periodToUse/:filterByColumn" element={<ReportsTeamsAllActivity user={user} />} />
                            <Route path="/reports/teamsallactivitybyuserreport/:tenantId" element={<ReportsTeamsAllActivityByUser user={user} />} />
                            <Route path="/reports/teamsallactivitybyipaddressreport/:tenantId" element={<ReportsTeamsAllActivityByIPAddress user={user} />} />
                            <Route path="/reports/teamsallactivitybyteamandchannelreport/:tenantId" element={<ReportsTeamsAllActivityByTeamOrChannel user={user} />} />
                        </>
                    )}

                    {isAuthenticated && userRole <= 2 && (
                        // Tenant Admin and Up.
                        <>
                            <Route path="/mytenant-validate" element={<MyTenantValidate user={user} setUser={setUser} />} />
                            <Route path="/mytenant-validate/:id" element={<MyTenantValidate user={user} setUser={setUser} />} />
                            <Route path="/mytenant-roles/:tenantId/:roleId" element={<MyTenantRoleForm user={user} />} />
                            <Route path="/mytenant-roles-assignusers/:tenantId/:roleId" element={<TenantRoleAssignUserForm user={user} />} />
                            <Route path="/tenant-update/:id" element={<UpdateTenant user={user} setUser={setUser} />} />
                            <Route path="/tenant-stats/:id" element={<MyTenantStats user={user} />} />
                            <Route path="/tenant-historical-import/:tenantId" element={<MyTenantSetupHistoricalImport user={user} />} />
                        </>

                    )}

                    {isAuthenticated && userRole <= 1 && (
                        // Company Admin and Up.
                        <>
                            <Route path="/mycompany" element={<MyCompany user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/mycompany/company-update/:id" element={<UpdateCompany user={user} />} />
                            <Route path="/mybilling" element={<MyBilling user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/mycompany-roles" element={<MyCompanyRoles user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                            <Route path="/mycompany-assign-role" element={<MyCompanyAssignRole user={user} />} />
                            <Route path="/mycompany-assign-role/:tenantId/:roleId" element={<MyCompanyAssignRole user={user} />} />
                            <Route path="/mycompany-users" element={<MyCompanyUsers user={user} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />} />
                        </>
                    )}

                    {isAuthenticated && user.isSystemAdmin && (
                        // System Admin Access Only.
                        <>
                            <Route path="/admin/tenant-update/:id" element={<UpdateTenantAdmin user={user} />} />
                            <Route path="/admin/tenant-create/:companyId" element={<CreateTenantAdmin user={user} />} />
                            <Route path="/admin/adminhome" element={<AdminHome user={user} />} />
                            <Route path="/admin/CompanyListAdmin" element={<CompanyListAdmin user={user} />} />
                            <Route path="/admin/company-update/:id" element={<UpdateCompanyAdmin user={user} />} />
                            <Route path="/admin/company-create" element={<AddCompany />} />
                            <Route path="/admin/MessageCenterAdmin" element={<MessageCenterAdmin user={user} />} />
                            <Route path="/admin/NewMessage" Component={NewMessage} element={<NewMessage />} />
                            <Route path="/admin/EditMessage/:id" Component={EditMessage} element={<EditMessage />} />
                            <Route path="/admin/tenant-admin/:companyId" element={<TenantsAdmin user={user} />} />
                            <Route path="/support/messagecenter/:companyId" element={<MessageCenter user={user} />} />
                            <Route path="/reports/diagnosticsreport" element={<ReportsDiagnostics user={user} />} />
                            <Route path="/admin/systemadminusers" element={<SystemAdminUsersList user={user} />} />
                            <Route path="/admin/assign-systemadmin-users" element={<SystemAdinAssignRole user={user} />} />
                            <Route path="/admin/HeartbeatForSearch" element={<HeartbeatForSearch user={user} />} />
                            <Route path="/admin/NewSearchOrchestrator" element={<NewSearchOrchestrator user={user} />} />
                            <Route path="/admin/EditSearchOrchestrator/:id" element={<EditSearchOrchestrator user={user} />} />
                            <Route path="/admin/ViewSearchOrchestratorLogs/:id" element={<ViewSearchOrchestratorLogs user={user} />} />
                            <Route path="/Admin/SystemEmails" element={<SystemEmailsList user={user} />} />
                            <Route path="/Admin/SystemEmail-update/:type" element={<SystemEmailForm user={user} />} />
                            <Route path="/Admin/SystemJobs" element={<SystemJobsList user={user} />} />
                            <Route path="/Admin/JobOrchestrators" element={<SystemOrchestratorsList user={user} />} />
                            <Route path="/Admin/UpdateJobOrchestrator" element={<JobOrchestratorForm user={user} />} />
                            <Route path="/Admin/UpdateJobOrchestrator/:orchestratorId" element={<JobOrchestratorForm user={user} />} />
                            <Route path="/Admin/system-diagnostics" element={<AdminSystemDiagnosticsReport user={user} />} />
                        </>
                    )}

                    {/* Catch-all (404 page not found or you are unauthorized to access). */}
                    <Route path="*" element={<NotFound location={location} />} />
                </Routes>
            </AuthenticatedTemplate >
        </PageLayout>
    );
}
export default App;