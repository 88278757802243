// React Components
import * as React from 'react';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Material UI Components
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Audit Vault Components
import AccessDenied from "../common/AccessDenied";
import RoleInfoList from "../role/RoleInfoList";

// Audit Vault Utilities
import { verifyUserCanAccessTenantData, getTenantUsersByRole, removeAuditVaultUserPermission } from "../../utilities/common-user-utils";
import catchAxiosErr2 from '../../utilities/common-axios-err-catch2';


export default function TenantRoleList(props) {

    // Component Constants
    const { user, 
      tenantId, 
      roleNum,
      sourcePage,
      setSelectedTenantChanged,
      selectedTenantChanged
     } = props;

    const navigate = useNavigate();

    const [loadingTenantAdminUsers, setLoadingTenantAdminUsers] = useState(true);
    const [loadingTenantReportUsers, setLoadingTenantReportUsers] = useState(true);
    const [loadingTenantViewerUsers, setLoadingTenantViewerUsers] = useState(true);

    const [tenantAdminUsers, setTenantAdminUsers] = useState([]);
    const [tenantReportUsers, setTenantReportUsers] = useState([]);
    const [tenantViewerUsers, setTenantViewerUsers] = useState([]);
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    const [openNotification, setOpenNotification] = useState(false);
    const [openNotificationMsg, setOpenNotificationMsg] = useState("User Removed from Role");

    const [tenantAdminsAccordian, setTenantAdminsAccordian] = useState(false); 
    const [tenantReportsAccordian, setTenantReportsAccordian] = useState(false);
    const [tenantViewersAccordian, setTenantViewersAccordian] = useState(false);
   

  async function fetchUsersByTenantIdAndRoleId(roleId) {
    if(user)
    {
        if (verifyUserCanAccessTenantData(user, tenantId))
        {
          switch (roleId)
          {
            case 2:
              setLoadingTenantAdminUsers(true);
              break;

            case 3:
              setLoadingTenantReportUsers(true);
              break;

            case 4:
              setLoadingTenantViewerUsers(true);
              break;
          }

          try 
          {
            var response = await getTenantUsersByRole(tenantId, roleId); 
            {
                switch (roleId)
                {
                  case 2:
                    setTenantAdminUsers(response);
                    setLoadingTenantAdminUsers(false);
                    break;

                  case 3:
                    setTenantReportUsers(response);
                    setLoadingTenantReportUsers(false);
                    break;

                  case 4:
                    setTenantViewerUsers(response);
                    setLoadingTenantViewerUsers(false);
                    break;
                }
                
            }
        }
        catch (e) {
            catchAxiosErr2(e, "TenantRoleList.fetchUsersByRoleData", setLoadingTenantAdminUsers, setServiceIsDownError);
        }
        }
        else
        {
            setAccessDenied(true);
        }
    }
}

  useEffect(() => {
    
    console.log(`roleNum: ${roleNum}`)
    console.log(`tenantId: ${tenantId}`)

    switch (Number(roleNum))
    {
      case 2:
        setOpenNotificationMsg("User Added to Role");
        setTenantAdminsAccordian(true);
        setOpenNotification(true);
        break;

      case 3:
        setOpenNotificationMsg("User Added to Role");
        setTenantReportsAccordian(true);
        setOpenNotification(true);
        break;

      case 4:
        setOpenNotificationMsg("User Added to Role");
        setTenantViewersAccordian(true);
        setOpenNotification(true);
        break;
    }

    if (selectedTenantChanged)
    {
      setTenantAdminsAccordian(false); 
      setTenantReportsAccordian(false);
      setTenantViewersAccordian(false);
      setSelectedTenantChanged(false);
    }

    handleAccordionLoad(roleNum);
  }, [tenantId]);

  const handleReviewMembers = (value) => {
    if (value  > 0) {
      navigate(`/mytenant-roles/${tenantId}/${value}`);
    }
  };

  const handleAddMembers = (roleIdValue) => {
    if (roleIdValue  > 0) 
    {
      switch (sourcePage)
      {
        case "MyTenant":
          navigate(`/mytenant-roles-assignusers/${tenantId}/${roleIdValue}`)
          break;

        case "MyCompany":
          navigate(`/mycompany-assign-role/${tenantId}/${roleIdValue}`)
          break;

      }
      
    }
  };

  const deletePerm = async (id, roleId) => {
    try {
      const response = await removeAuditVaultUserPermission(id);
      if (response)
      {
        setOpenNotificationMsg("User Removed from Role");
        fetchUsersByTenantIdAndRoleId(roleId);
        setOpenNotification(true);
      }
    } catch (e) {
        console.log("ERROR: TenantRoleList.deletePerm")
        console.log(e);
        setServiceIsDownError(true);
    }
    finally{
      setLoadingTenantAdminUsers(false);
    }
  };

  const handleRemoveAccess = async (permId, roleId) => {
    if (permId > 0) {
      deletePerm(permId, roleId);
    }
  };

  const handleAccordionLoad = async (roleId) =>
  {
    switch (Number(roleId))
    {
      case 2:
        setLoadingTenantAdminUsers(tenantAdminsAccordian);
        if (!tenantAdminsAccordian)
        {
          fetchUsersByTenantIdAndRoleId(2);
        }
        setTenantAdminsAccordian(!tenantAdminsAccordian);
        break;

      case 3:
        setLoadingTenantReportUsers(tenantReportsAccordian);
        if (!tenantReportsAccordian)
        {
          fetchUsersByTenantIdAndRoleId(3);
        }
        setTenantReportsAccordian(!tenantReportsAccordian);
        break;

      case 4:
        setLoadingTenantReportUsers(tenantViewersAccordian);
        if (!tenantViewersAccordian)
        {
          fetchUsersByTenantIdAndRoleId(4);
        }
        setTenantViewersAccordian(!tenantViewersAccordian);
        break;
    }
  }

// For snackbar close action.
const handleNotificationClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpenNotification(false);
};

// For snackbar notification action.
const openNotificationAction = (
    <React.Fragment>
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleNotificationClose}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    </React.Fragment>
);
  
 // Component UI
  if (accessDenied)
  {
    return (<AccessDenied></AccessDenied>);
  }
    
return(
    <><Card sx={{ width: 600 }} variant="outlined">
    <CardContent>
      <RoleInfoList
        tenantId={tenantId}
        handleAccordionLoad={handleAccordionLoad}
        handleReviewMembers={handleReviewMembers}
        handleAddMembers={handleAddMembers}
        handleRemoveAccess={handleRemoveAccess}
        acccordianExpand={tenantAdminsAccordian}
        roleName={"Tenant Administrators"}
        roleDescription={"Privileged users who can manage the specified Tenant in this application."}
        roleId={2}
        loading={loadingTenantAdminUsers}
        users={tenantAdminUsers}
      ></RoleInfoList>

      <RoleInfoList
        tenantId={tenantId}
        handleAccordionLoad={handleAccordionLoad}
        handleReviewMembers={handleReviewMembers}
        handleAddMembers={handleAddMembers}
        handleRemoveAccess={handleRemoveAccess}
        acccordianExpand={tenantReportsAccordian}
        roleName={"Tenant Report Readers"}
        roleDescription={"Can run and view reports, insight searches, and view audit information for the specified Tenant."}
        roleId={3}
        loading={loadingTenantReportUsers}
        users={tenantReportUsers}
      ></RoleInfoList>

      <RoleInfoList
        tenantId={tenantId}
        handleAccordionLoad={handleAccordionLoad}
        handleReviewMembers={handleReviewMembers}
        handleAddMembers={handleAddMembers}
        handleRemoveAccess={handleRemoveAccess}
        acccordianExpand={tenantViewersAccordian}
        roleName={"Tenant Viewers"}
        roleDescription={"Can view Tenant Status, and can be used to manage access to SharePoint View Item Audit History Page for the Tenant."}
        roleId={4}
        loading={loadingTenantViewerUsers}
        users={tenantViewerUsers}
      ></RoleInfoList>

    </CardContent>
    <CardActions>
    </CardActions>
  </Card><div>
      <Snackbar
        open={openNotification}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
        message={openNotificationMsg}
        action={openNotificationAction} />
    </div></>
);
}
  