// React Components
import * as React from 'react';
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

// Audit Vault Components
import AccessDenied from '../common/AccessDenied';
import ServiceIsDown from '../common/ServiceIsDown';
import ProgressBar from '../common/ProgressBar';

// Audit Vault Utilities
import getRole from '../../utilities/common-role-utils';
import {verifyUserCanAccessTenantData, searchForCompanyUsersByName, getAllCompanyUsers, getAllSystemUsers } from '../../utilities/common-user-utils';
import { getCompanyByCompanyId } from '../../utilities/common-company';
import { encryptKey } from '../../utilities/common-encrypt-util';

// Audit Vault Constants
import { ROLES } from "../../constants/constants-roles";


const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

const columns = [
    { id: 'chk', label: ' ', minWidth: 1, align: 'left' },
    { id: 'displayName', label: 'Display Name', minWidth: 200, align: 'left' },
  ];

 
export default function UserSearchAssignRoleForm(props) {

    const { user, tenantId, roleId, sourcePage } = props;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [users, setUsers] = useState([]);
    const [tenantList, setTenantList] = useState([]);
    const [company, setCompany] = useState(false);

    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const [auditVaultRole, setAuditVaultRole] = useState(1);
    const [selectedTenant, setSeletedTenant] = useState(0);
    var auditVaultRoleRef = useRef(1);

    const [accessDenied, setAccessDenied] = useState(false);
    const [noUsersError, setNoUsersError] = useState(false);
    const [tenantErr, setTenantError] = useState(false);
    const [roleErr, setRoleError] = useState(false);

    const [newUsers, setNewUsers] = useState([]);

    useEffect(() => {
      
      async function fetchData() {

        if (loading)
        {
          if (roleId)
          {
            setAuditVaultRole(roleId);
          }
          else if (roleId == 0)
          {
            setAuditVaultRole(0);
          }
          else
          {
            setAuditVaultRole(1);
          }
    
          if (tenantId > 0)
          {
            var canAccessTenant = verifyUserCanAccessTenantData(user, tenantId);
            console.log(`canAccessTenant: ${canAccessTenant}`)
            if (!canAccessTenant && tenantId > 0)
              setAccessDenied(true);
            else
              setSeletedTenant(tenantId);
          }
          else
          {
            var response = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
            if (response)
            {
              setCompany(response);
              console.log(response);
              setTenantList(response.tenantList);
            }
          }
          setLoading(false);
        }
      }
      fetchData();
    }, []);


  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
  };

  const handleChecked = (key, isChecked) => {
      key.isActive = isChecked;
      setNewUsers([...newUsers, key])
      newUsers.push(key)
  }

  


  async function getSystemAdminPayload(startIndex, endIndex) {

  var payload = '[';
  for (var i = startIndex; i < endIndex; i++) 
  {
      payload = payload + '{';
      payload = payload + `"id": 0,`;
      payload = payload + `"microsoftGraphId": "${await encryptKey(newUsers[i].graphUserId)}", `;
      payload = payload + `"userEmail": "${await encryptKey(newUsers[i].email)}", `;
      payload = payload + `"displayName": "${await encryptKey(newUsers[i].displayName)}", `;
      payload = payload + `"companyId": 0, `;
      payload = payload + `"isActive": ${newUsers[i].isActive}, `;
      payload = payload + `"isSystemAdmin": true, `;
      payload = payload + `"isCompanyAdmin": false, `;
      payload = payload + `"permissions":[`;
      payload = payload + ']';
      payload = payload + '}';

      if (i != newUsers.length -1)
      {
          payload = payload + ',';
      }
  }
  payload = payload + ']';
  console.log(payload);
  return payload;
}

  async function getPayload(startIndex, endIndex) {

      var payload = '[';
      for (var i = startIndex; i < endIndex; i++) 
      {
        var isCompanyAdmin = false;
        if (auditVaultRole == 1)
          isCompanyAdmin = true;

          payload = payload + '{';
          payload = payload + `"id": 0,`;
          payload = payload + `"microsoftGraphId": "${await encryptKey(newUsers[i].graphUserId)}", `;

          if (newUsers[i].email)
            payload = payload + `"userEmail": "${await encryptKey(newUsers[i].email)}", `;
          else
            payload = payload + `"userEmail": "${await encryptKey("")}", `;

          payload = payload + `"displayName": "${await encryptKey(newUsers[i].displayName)}", `;
          payload = payload + `"companyId": ${user.companyId}, `;
          payload = payload + `"isActive": ${newUsers[i].isActive}, `;
          payload = payload + `"isSystemAdmin": false, `;
          payload = payload + `"isCompanyAdmin": ${isCompanyAdmin}, `;
          payload = payload + `"permissions":[`;
          
          if (!isCompanyAdmin)
          {
            payload = payload + `{`;
            payload = payload +`"id": 0, `;
            payload = payload +`"userId": 0, `;
            payload = payload +`"companyId": ${user.companyId}, `;
            payload = payload +`"tenantId": ${selectedTenant}, `;
            payload = payload +`"roleId": ${auditVaultRole}, `;
            payload = payload +`"accessToSp": true, `;
            payload = payload +`"accessToExchange": true, `;
            payload = payload +`"accessToAzureAd": true, `;
            payload = payload +`"accessToTeams": true, `;
            payload = payload +`"accessToDlp": true, `;
            payload = payload +`"accessToGeneral": true `;
            payload = payload +`} `;
          }
          
          payload = payload + ']}';
          
          if (i != newUsers.length -1)
          {
              payload = payload + ',';
          }
    }
    payload = payload + ']';
    
    return payload;
  }

  function validateInfoHandler() 
  {
      let isError = false;
      
      if (auditVaultRole == 1)
        setSeletedTenant(0);
      
      if (!roleId && auditVaultRole > 1 && selectedTenant <= 0) {
        
          isError = true;
          setTenantError(true);
      }
      else
      {
        setTenantError(false);
      }

      if (auditVaultRole > 1 && selectedTenant <= 0) {
          isError = true;
          setTenantError(true);
      }
      else
      {
        setTenantError(false);
      }

      if (roleId == 6 && auditVaultRole == 6)
      {
        isError = true;
        setRoleError(true);
      }
      else
      {
        setRoleError(false);
      }
      
      if (newUsers.length == 0)
      {
          isError = true;
          setNoUsersError(true);
      }
      else
      {
        var found = false;
        for(var x=0; x < newUsers.length; x++)
        {
          var activeUser = newUsers.filter(el => el.graphUserId == newUsers[x].graphUserId && el.isActive == true);
          var inactiveUser = newUsers.filter(el => el.graphUserId == newUsers[x].graphUserId && el.isActive == false);
          if (activeUser.length > inactiveUser.length)
            found= true;
          
        }
        if (found)
          setNoUsersError(false);
        else
        {
          isError = true;
          setNoUsersError(true);
        }
      }

      return isError;
  }

   
  const handleSearch = async () => {
    
    setLoading(true);
    try
    {
      if (auditVaultRole == 0)
      {
          var response = await getAllSystemUsers(user.microsoftGraphId);
          if (response)
          {
              setUsers(response);
          }
      }
      else if (searchQuery.length > 0)
      {
          var response = await searchForCompanyUsersByName(user.companyId, searchQuery);
          if (response)
          {
              setUsers(response);
          }
      }
      else
      {
        var response = await getAllCompanyUsers(user.companyId);
        if (response)
        {
            setUsers(response);
        }
      }
    }
    catch (e)
    {
      console.log("UserSearchAssignRoleForm.jsx HandleSearch Error:");
      console.log(e);
      setServiceIsDownError(true);
    }
    finally{
      setLoading(false);
    }
  }

  async function handleUploadUsers(startIndex, endIndex) 
  {
      var payload = "";
      if (auditVaultRole == 0)
      {
          payload = await getSystemAdminPayload(startIndex, endIndex);
      }
      else
      {
        payload = await getPayload(startIndex, endIndex);
      }

      var response = await axios.post(`${WEB_API_URL}User/InsertAuditVaultUsers`, null, {
          params: {
              key: payload,
            }
      });

      if (response)
      {
        switch (sourcePage)
        {
          case "MyCompany":
            navigate(`/mycompany-roles?successfulAdd=true&roleId=${auditVaultRole}`);
            break;

          case "MyTenant":
            if (selectedTenant > 0)
              navigate(`/Tenant/MyTenant?roleId=${auditVaultRole}&tenantId=${selectedTenant}`);
            else
            navigate(`/Tenant/MyTenant?roleId=${auditVaultRole}`);
            break;

          case "SystemAdmin":
            navigate(`/admin/systemadminusers?roleId=${auditVaultRole}`);
            break;

          case "MyCompanyUsers":
            navigate(`/mycompany-users?successfulAdd=true`);
            break;
        }
      }
  }
  
  const handleInsert = async () => {

      let isError = validateInfoHandler();
      if (!isError)
      {
          if (!loading) {
              try
              {
                  let i = 0;
                  do 
                  {
                    if (i + 5 <= newUsers.length)
                    {
                        await handleUploadUsers(i, i + 5);
                    }
                    else
                    {
                        await handleUploadUsers(i, newUsers.length);
                    }
                    i = i + 5;
                  }
                  while (i < newUsers.length);
                  
              }
              catch(e)
              {
                  console.log("ERROR: UserSearchAssignRoleForm.handleInsert");
                  console.log(e);
                  setServiceIsDownError(true);
              }
              finally{
                  setLoading(false);
              }
          }
        
      }
          
  }

  if (serviceIsDownError) {
      return (<><ServiceIsDown></ServiceIsDown></>);
  }

  if (accessDenied)
  {
    return (<AccessDenied></AccessDenied>)
  }



  if (user)
  {
    return (
      <>
       <Grid container spacing={2}>
          <Grid item md={12} sx={{minWidth: 550}}>
          <Card sx={{ minWidth: 550, maxWidth: 550}} variant="outlined">
            <CardContent>
            <Table sx={{ minWidth: 530,  maxWidth: 530,
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none"
                  }
                }} size="small">
                <TableHead>
                </TableHead>
                <TableBody>
                <TableRow>
                  <TableCell key="search-col-desc" sx={{paddingBottom: "10px"}}><b>User:</b></TableCell>
                  <TableCell key="search-col" sx={{paddingBottom: "10px"}}>
                  <TextField
                    id="search-bar"
                    style={{ width: 250 }}
                    value={searchQuery}
                    onChange={event => setSearchQuery(event.target.value)}
                    label="Name starts with"
                    variant="outlined"
                    placeholder="Search..."
                    size="small"
                  />
                  <Button type="submit" aria-label="search" onClick={() => handleSearch()}> <SearchIcon /></Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  
                  {roleId == 6 &&
                  <>
                    <TableCell key="role-desc"><b>Select Role:</b></TableCell>
                    <TableCell key="role-select-cell">
                          <TextField required
                            select
                            name="auditVaultRoleRef"
                            inputRef={auditVaultRoleRef}
                            value={auditVaultRole}
                            variant="outlined"
                            size="small"
                            style={{ width: 250 }}
                            InputLabelProps={{ shrink: true }}
                            onChange={event => setAuditVaultRole(event.target.value)}
                            error={roleErr}
                            helperText={roleErr ? "Role is Required" : ""}
                          >
                            {ROLES.map((option) => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </TableCell>
                      </>
                  }
                  {roleId != 6 && auditVaultRole >=0 &&
                    <>
                    <TableCell key="role-desc2" ><b>Role:</b></TableCell>
                    <TableCell key="role-select-cell2">{getRole(Number(roleId))}</TableCell>
                    </>  
                  }
                  
                </TableRow>
                {tenantId == 0 && auditVaultRole > 0 &&
                  <>
                  <TableRow>
                  <TableCell key="role-desc3"><b>For Tenant:</b></TableCell>
                  <TableCell key="role-select-cell3">
                    <TextField
                    required
                    select
                    id="tenant-select"
                    onChange={event => setSeletedTenant(event.target.value)}
                    variant="outlined"
                    size="small"
                    style={{ width: 250 }}
                    InputLabelProps={{ shrink: true }}
                    error={tenantErr}
                    helperText={tenantErr ? "Tenant is Required" : ""}
                >
                    {tenantList.map((row) => (
                        <MenuItem key={row.id} value={row.id}>
                            {row.tenantUrl}
                        </MenuItem>
                    ))}
                  </TextField>
                  </TableCell>
                  </TableRow>
                  </>
                }
                <TableRow><TableCell key="tcbreak" colSpan={2}><hr></hr></TableCell></TableRow>
                <TableRow>
                  <TableCell key="UsersList" colSpan={2}>
                  {users && users.length == 0 &&
                  <>
                    <Alert severity="info">
                      <AlertTitle>Please search for Users</AlertTitle>
                    </Alert>
                    
                  </>
                  }
                  <Typography component="div" variant='h6' sx={{paddingTop: 1}}><ProgressBar loading={loading} /></Typography>
                  {users && users.length > 0 &&
                  <>
                  <Table sx={{ minWidth: 400 }} size="small">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={`RowID-${row.graphUserId}`}>
                              {columns.map((column) => {
                                const value = row[column.id];
                                switch (column.id) {
                                  case 'chk':
                                    return (<TableCell key={'CHK' + row.id} align={column.align}><Checkbox onClick={(event) => handleChecked(row, event.target.checked)}></Checkbox></TableCell>);

                                  default:
                                    return (
                                      <TableCell key={row.id} align={column.align}>
                                        {value
                                          ? value.toString()
                                          : value}
                                      </TableCell>
                                    );
                                }
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table><TablePagination
                      rowsPerPageOptions={[25, 50, 100]}
                      component="span"
                      count={users.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage} /></>
              }
                    </TableCell>
                </TableRow>
                </TableBody>
            </Table>
            <Grid item md={12}>
            {noUsersError &&
            (
                <Alert severity="error">
                  <AlertTitle>Please select some users to assign roles to.</AlertTitle>
                </Alert>
            )}
            {roleErr &&
            (
                <Alert severity="error">
                  <AlertTitle>Please select a Role.</AlertTitle>
                </Alert>
            )}
              {tenantErr &&
            (
                <Alert severity="error">
                  <AlertTitle>Please select a Tenant.</AlertTitle>
                </Alert>
            )}
            </Grid>
            </CardContent>
            <CardActions>
              {users && users.length > 0 &&
                  <Button variant="contained" className="ml-auto" onClick={() => handleInsert()}> Assign Users to Role </Button>
              }
              {users && users.length == 0 &&
                <Button type="submit" aria-label="search" onClick={() => handleSearch()}>Search <SearchIcon sx={{ paddingLeft: '5px' }} /></Button>
              }
            </CardActions>
          </Card>
          </Grid>
        </Grid>
        </>
      );
  }
}